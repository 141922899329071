import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../spinner/spinner";
import AuthenticationService from "../service/authService";
import { toast } from "react-toastify";
import commonService from "../service/commonService";
import backgroundImg from '../../assets/logo/AdobeStock_616564080.jpeg';
import VersionModal from "../../components/initiatives/modal/versionModal";
import { useMsal } from "@azure/msal-react";

const errorState: any = {
  userName: { hasError: false, required: true },
  password: { hasError: false, required: true },
};

const loginInitModel: Login = {
  userName: "",
  password: "",
};
export default function Login() {
  const [loginModel, setLoginModel] = useState<Login | any>(loginInitModel);
  const [error, setError] = useState<any>(errorState);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const dbkProfile = commonService.dbkProfile;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let userId = params.get("userId");
    let emailValidationToken = params.get("token");
    if(userId && emailValidationToken){
      verifyEmail(userId, emailValidationToken);
    }
    checkError();
  }, [loginModel, error]);

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    if(fieldName === 'password') {
      field.hasError = !commonService.passwordPattern?.test(value);
    } else {
      field.hasError = !value;
    }
    const model = JSON.parse(JSON.stringify(loginModel));
    model[fieldName] = value;
    setLoginModel(model);
    error[fieldName] = field;
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if ((errorState[key]?.required && !loginModel[key])
      || (key === 'password' && error?.password?.hasError)) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  const onLogin = (e?: any) => {
    e?.preventDefault();
    setLoading(true);
    AuthenticationService.authenticate(loginModel).then((response: any) => {
      commonService.showMessage(response?.data || {});
      const result = response?.data?.result;
      localStorage.setItem('currentUser', JSON.stringify(result || ''));
      if(result) {
        commonService.parseNavigate(`/redirect?path=/`, navigate);
      } else {
        setLoading(false);
      }
    }).catch((e: any) => {
      toast.error(`${e?.response?.data?.message}`);
      setLoading(false);
    });
  };

  const verifyEmail = (userId: string, token: string) => {
    setLoading(true);
    AuthenticationService.verifyEmail(userId, token).then((response: any) => {
      setLoading(false);
      if(response?.data) {
        commonService.showMessage(response?.data || {});
        navigateURL('/login');
      }
    }).catch((e: any) => {
      toast.error(`${e?.response?.data?.message}`);
      setLoading(false);
    });
  };

  const navigate = useNavigate();
  const navigateURL = (link: string) => {
    navigate(link);
  };


  const [showVersionModal, setShowVersionModal] = useState(false);

  const closeVersionModal = () => {
    setShowVersionModal(false);
  };

  const openVersionModal = () => {
    setShowVersionModal(true);
  };

  const { instance } = useMsal();

  const handleLogin = () => {
      instance.loginPopup().catch(e => {
          commonService.showResponseError(e);
      });
  };

  return (
    <>
    { showVersionModal && 
    <VersionModal show={showVersionModal} closeModal={closeVersionModal} /> }
      <div className="flex min-h-full flex-1 h-screen">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex justify-center">
              <img
                className="h-16 w-auto"
                src={dbkProfile.imgUrl}
                alt={dbkProfile.name}
              />
            </div>
            <h2 className="mt-8 text-2xl text-center font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-sm text-center leading-6 text-gray-500">
              Not a member?{" "}
              <a
                href="#"
                onClick={() => navigateURL("/register")}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Start a 14 day free trial
              </a>
            </p>

            <div className="mt-10">
              <div>
                <form className="space-y-6" onSubmit={onLogin}>
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        required
                        value={loginModel.userName}
                        onChange={(e) =>
                          onInputChange("userName", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.userName?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : ""
                        )}
                      />
                    </div>
                    {error?.userName?.hasError && (
                      <p
                      className="mt-2 text-sm text-red-600"
                      id="order-error"
                    >
                      Username is required.
                    </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        value={loginModel.password}
                        onChange={(e) =>
                          onInputChange("password", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.password?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : ""
                        )}
                      />
                    </div>
                    {error?.password?.hasError && (
                      <p
                      className="mt-2 text-sm text-red-600"
                      id="order-error"
                    >
                      Has minimum 8 characters, At least one uppercase, one
                      lowercase, one digit, one special character.
                    </p>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={hasError || loading}
                      className={commonService.classNames(
                        "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                        hasError || loading ? "disabled:opacity-75" : ""
                      )}
                    >
                      <Spinner show={loading} />
                      Login
                    </button>
                    <a
                      href="#"
                      onClick={() => openVersionModal()}
                      className="font-semibold hover:text-indigo-500 text-sm flex justify-center underline mt-4 font-light"
                    >
                      Version
                    </a>
                  </div>
                </form>
                <div>
                  <button
                    type="submit"
                    onClick={handleLogin}
                    disabled={loading}
                    className={commonService.classNames(
                      "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 mt-5",
                      loading ? "disabled:opacity-75" : ""
                    )}
                  >
                    <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                      >
                      <path d="M1.5 3.5h10v9h-10v-9zm11 0h10v9h-10v-9zm0 10h10v9h-10v-9zm-11 0h10v9h-10v-9z" />
                      </svg>
                    <Spinner show={loading} />
                      Sign in with Microsoft
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImg}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
