import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../spinner/spinner";
import AuthenticationService from "../service/authService";
import commonService from "../service/commonService";
import { toast } from "react-toastify";
import backgroundImg from '../../assets/logo/AdobeStock_616564080.jpeg';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const errorState: any = {
  firstName: { hasError: false, required: true },
  lastName: { hasError: false, required: true },
  userName: { hasError: false, required: true },
  email: { hasError: false, required: true },
  password: { hasError: false, required: true },
  companyName: { hasError: false, required: true },
  companySize: { hasError: false, required: true },
  companyWebsite: { hasError: false, required: true }
};

const registrationModel: Register = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  companyName: "",
  companySize: "",
  companyWebsite: "",
  phone: "",
  timeZone: "Pacific Standard Time",
  customerId: "",
  invitationCode: ""
};

export default function Registration() {
  const [registerModel, setRegisterModel] = useState<Register | any>(
    registrationModel
  );
  const [error, setError] = useState<any>(errorState);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const dbkProfile = commonService.dbkProfile;
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
  const [provider, setProvider] = useState<string>('');
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const initModel: Register = JSON.parse(JSON.stringify(registrationModel));
    initModel.companySize = commonService.companySizeList[0].value;
    initModel.timeZone = commonService.currentTimeZone();
    const params = new URLSearchParams(window.location.search);
    let customerId = params.get("customerId");
    let code = params.get("code");
    let email = params.get("email");
    let paramProvider = params.get("provider") ?? "";
    setProvider(paramProvider);
    setSelectedCustomerId(customerId ?? '');
    if(customerId){
      initModel.customerId = customerId;
      initModel.invitationCode = code ?? '';
      initModel.email = email ?? '';
      if(provider === "Microsoft"){
        initModel.userName = email ?? '';
      }
      errorState.companyName.required = false;
      errorState.companySize.required = false;
      errorState.companyWebsite.required = false;
    }
    setRegisterModel(initModel);
  }, [provider]);

  useEffect(() => {
    checkError();
  }, [registerModel, error]);

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    if(fieldName === 'password') {
      field.hasError = !commonService.passwordPattern?.test(value);
    } else if(fieldName === 'email') {
      field.hasError = !value || !commonService.isValidEmail(value);
    } else {
      field.hasError = !value;
    }
    const model = JSON.parse(JSON.stringify(registerModel));
    model[fieldName] = value;
    setRegisterModel(model);
    error[fieldName] = field;
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if (key === 'password' && provider === 'Microsoft') {
        continue;
      }
      if ((errorState[key]?.required && !registerModel[key]) ||
      (key === 'password' && provider !== 'Microsoft' && error?.password?.hasError) ||
      (key === 'email' && error?.email?.hasError)) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  const onRegister = async (e?: any) => {
    e?.preventDefault();
    let oid;
    if (provider === "Microsoft" && accounts.length === 0) {
      // User is not logged in with MSAL, prompt them to log in
      try {
        const loginResponse = await instance.loginPopup(loginRequest); // Wait for the login to complete
        const msalAccount = loginResponse.account; // Get the logged-in account
        oid = msalAccount?.idTokenClaims?.oid; // Extract the OID
        console.log("fetched oid ", oid)
      } catch (error) {
          toast.error("Login required to proceed with registration.");
          setLoading(false);
          return;
      }
    }
    if(provider === "Microsoft" && accounts.length > 0) {
      // If already logged in, fetch the OID from the existing account
      const msalAccount = accounts[0];
      oid = msalAccount.idTokenClaims?.oid;
    }

    if (provider === "Microsoft" && !oid) {
      toast.error("Unable to retrieve OID for registration.");
      setLoading(false);
      return;
    }

    const entry: Register = JSON.parse(JSON.stringify(registerModel));
    if(!entry.customerId) {
      delete entry.customerId;
    } else {
      entry.companySize = '';
    }
    if(provider === "Microsoft" && oid){
      entry.userId = oid;
    }
    setLoading(true);
    AuthenticationService.register(entry)
      .then((response: any) => {
        commonService.showMessage(response?.data || {});
        if (response?.data?.isSuccess) {
          navigateURL("/login");
        }
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const navigate = useNavigate();
  const navigateURL = (link: string) => {
    navigate(link);
  };

  return (
    <div className="flex min-h-full flex-1 h-screen">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex justify-center">
              <img
                className="h-16 w-auto"
                src={dbkProfile.imgUrl}
                alt={dbkProfile.name}
              />
            </div>
            <h2 className="mt-3 text-2xl text-center font-bold leading-9 tracking-tight text-gray-900">
              Register
            </h2>
            <p className="mt-2 text-sm text-center leading-6 text-gray-500">
              Already a member?{" "}
              <a
                href="#"
                onClick={() => navigateURL("/login")}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Click here to login
              </a>
            </p>

            <div className="mt-4">
              <div>
                <form className="space-y-0" onSubmit={onRegister}>
                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        First name
                      </label>
                      <div className="mt-1 mb-1">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          value={registerModel.firstName}
                          onChange={(e) =>
                            onInputChange("firstName", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.firstName?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.firstName?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        First Name is required.
                      </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Last name
                      </label>
                      <div className="mt-1 mb-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          value={registerModel.lastName}
                          onChange={(e) =>
                            onInputChange("lastName", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.lastName?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.lastName?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        Last Name is required.
                      </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Username
                    </label>
                    <div className="mt-1 mb-1">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        required
                        disabled={provider === "Microsoft"}
                        value={registerModel.userName}
                        onChange={(e) =>
                          onInputChange("userName", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.userName?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : "",
                            provider === "Microsoft" ? "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200": ""
                        )}
                      />
                    </div>
                    {error?.userName?.hasError && (
                      <p
                      className="mt-2 text-sm text-red-600"
                      id="order-error"
                    >
                      Username is required.
                    </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-1 mb-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        disabled={!!selectedCustomerId}
                        required
                        value={registerModel.email}
                        onChange={(e) =>
                          onInputChange("email", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.email?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : "",
                            !!selectedCustomerId ? "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200": ""
                        )}
                      />
                    </div>
                    {error?.email?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          {!registerModel.email ? 'Email is required.': 'Invalid Email'}
                        </p>
                    )}
                  </div>

                  {provider !== "Microsoft" && 
                  
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-1 mb-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required={provider !== "Microsoft"}
                        value={registerModel.password}
                        onChange={(e) =>
                          onInputChange("password", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.password?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : ""
                        )}
                      />
                    </div>
                    {error?.password?.hasError && (
                      <p
                      className="mt-2 text-sm text-red-600"
                      id="order-error"
                    >
                      Has minimum 8 characters, At least one uppercase, one lowercase, one digit, one special character.
                    </p>
                    )}
                  </div>
                  }

                  {
                    !selectedCustomerId &&
                    <>
                      <div>
                    <label
                      htmlFor="companyname"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Company Name
                    </label>
                    <div className="mt-1 mb-1">
                      <input
                        id="companyname"
                        name="companyname"
                        type="text"
                        required
                        value={registerModel.companyName}
                        onChange={(e) =>
                          onInputChange("companyName", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.companyName?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : ""
                        )}
                      />
                    </div>
                    {error?.companyName?.hasError && (
                      <p
                      className="mt-2 text-sm text-red-600"
                      id="order-error"
                    >
                      Company Name is required.
                    </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="companySize"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Company Size
                    </label>
                    <div className="mt-1 mb-1">
                      <select
                        id="companySize"
                        name="companySize"
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.companySize?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : ""
                        )}
                        value={registerModel.companySize}
                        required
                        onChange={(e) =>
                          onInputChange("companySize", e?.target?.value)
                        }
                      >
                        {commonService.companySizeList?.map((size: {name: string, value: string}) => (
                            <option
                              value={size.value}
                              key={size.value}
                            >
                              {size.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {error?.companySize?.hasError && (
                      <p
                      className="mt-2 text-sm text-red-600"
                      id="order-error"
                    >
                      Company Size is required.
                    </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="companyWebsite"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Company Website
                    </label>
                    <div className="mt-1 mb-1">
                      <input
                        id="companyWebsite"
                        name="companyWebsite"
                        type="text"
                        required
                        value={registerModel.companyWebsite}
                        onChange={(e) =>
                          onInputChange("companyWebsite", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                          error?.companyWebsite?.hasError
                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                            : ""
                        )}
                      />
                    </div>
                    {error?.companyWebsite?.hasError && (
                      <p
                      className="mt-2 text-sm text-red-600"
                      id="order-error"
                    >
                      Company Website is required.
                    </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone
                    </label>
                    <div className="mt-1 mb-1">
                      <input
                        id="phone"
                        name="phone"
                        type="number"
                        required
                        value={registerModel.phone}
                        onChange={(e) =>
                          onInputChange("phone", e?.target?.value)
                        }
                        className={commonService.classNames(
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        )}
                      />
                    </div>
                  </div>
                    </>
                  }

                  <div>
                    <label
                      htmlFor="timeZone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Timezone
                    </label>
                    <div className="mt-1 mb-1">
                      <select
                        id="timezone"
                        name="timezone"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                        value={registerModel.timeZone}
                        onChange={(e) =>
                          onInputChange("timeZone", e?.target?.value)
                        }
                      >
                        {commonService.timeZones()?.map((timeZone: string) => (
                            <option
                              value={timeZone}
                              key={timeZone}
                            >
                              {timeZone}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={hasError || loading}
                      className={commonService.classNames(
                        "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                        hasError || loading ? "disabled:opacity-75" : "",
                        "mt-4"
                      )}
                    >
                      <Spinner show={loading} />
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImg}
            alt=""
          />
        </div>
      </div>
  );
}
