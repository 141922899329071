import React from 'react';

interface AzureResourceParserProps {
  resourceId: string;
}

interface AzureResourceData {
  subscriptions?: string;
  resourcegroups?: string;
  providers?: string;
  resourcetype?: string;
  name?: string;
  [key: string]: string | undefined;
}

class AzureResourceParser extends React.Component<AzureResourceParserProps> {
  private data: Readonly<AzureResourceData> = Object.freeze(this.parse(this.props.resourceId));

  get subscriptionId(): string{
    return this.data.subscriptions ?? '';
  }

  get resourceGroup(): string{
    return this.data.resourcegroups ?? '';
  }

  get resourceProvider(): string{
    return this.data.providers ?? '';
  }

  get resourceType(): string {
    return this.data.resourcetype ?? '';
  }

  get resourceName(): string{
    return this.data.name ?? '';
  }

  getProperty(key: string): string{
    return this.data[key.toLowerCase()] ?? '';
  }

  private parse(resourceId: string): AzureResourceData {
    if (!resourceId || typeof resourceId !== 'string') {
      throw new Error('Invalid ResourceId. ResourceId must be a non-empty string.');
    }

    const data: AzureResourceData = {};
    const queryStringIndex = resourceId.indexOf('?');

    if (queryStringIndex > -1) {
      resourceId = resourceId.substring(0, queryStringIndex);
    }

    const parts = resourceId.split('/').filter(Boolean);

    for (let i = 0; i < parts.length; i += 2) {
      data[parts[i].toLowerCase()] = parts[i + 1];
    }

    data.name = parts[parts.length - 1];
    data.resourcetype = parts[parts.length - 2];

    return data;
  }
}

export default AzureResourceParser;
