import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import OpportunityService from "../../../app/service/opportunityService";
import { toast } from "react-toastify";
import { ModalWrapper } from "../../common/modalWrapper";
import { PlanForm } from "../../../app/common/commonControls";
interface Props {
  show: boolean;
  closeModal?: any;
  plan?: OpportunityPlanItem;
  isEdit?: boolean;
  project: ProjectItem | undefined;
  refreshProject?: any;
  fromResourceModal?: boolean;
}

export default function InitiativesModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  const editPlanName = props.plan?.name;

  const [seq, setSeq] = useState<string>("");

  const [errorCheck, setErrorCheck] = useState<boolean>(false);
  const [onErrorCheck, setOnErrorCheck] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
      checkError();
    }
  }, [props.show]);

  const checkError = () => {
    setErrorCheck(true);
    setTimeout(() => {
      setErrorCheck(false);
    }, 10);
  }

  const init = () => {
    setPlan(initPlan());
  };

  const initPlan = (): OpportunityPlanItem => {
    if (props.isEdit) {
      const currentPlan = JSON.parse(JSON.stringify(props.plan ?? {}));
      currentPlan.startOn = setDate(currentPlan.startOn);
      currentPlan.dueOn = setDate(currentPlan.dueOn);
      return currentPlan || {};
    }
    return commonService.planItem();
  };

  const setDate = (value: string) => {
    return value?.toString()?.split("T")[0] || "";
  };

  const [plan, setPlan] = useState<OpportunityPlanItem | any>(initPlan());

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onCheckError = () => {
    setOnErrorCheck(true);
    setTimeout(() => {
      setOnErrorCheck(false);
    }, 10);
  }

  const createPlan = () => {
    onCheckError();
    checkError();
    if (!hasError) {
      const body: CreateOrUpdateOpportunityPlanRequest = {
        accountId: commonService.getAccountId(),
        projectId: props.project?.id ?? "",
        planCode: plan.planCode,
        name: plan.name,
        description: plan.description,
        value: plan.value,
        startOn: plan.startOn,
        dueOn: plan.dueOn,
        status: plan.status,
      };
      if (props?.isEdit) {
        body.id = plan?.id;
      } else {
        body.planCode = `${plan.planCode}`; //${seq}
      }
      setLoading(true);
      if (props?.isEdit) {
        OpportunityService.updateOpportunityPlan(body)
          .then((response: any) => {
            setLoading(false);
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              closeModal(true);
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      } else {
        OpportunityService.createOpportunityPlan(body)
          .then((response: any) => {
            commonService.showMessage(response?.data || {});
            if (response?.data?.isSuccess) {
              const result = response?.data?.result || {};
              addContact(result);
            }
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      }
    }
  };

  const addContact = (newPlan: OpportunityPlanItem) => {
    const userData = commonService.userDetail();
    const body: CreateOrUpdateOpportunityPlanContactsRequest = {
      accountId: newPlan?.accountId,
      projectId: newPlan?.projectId || "",
      opportunityPlanId: newPlan?.id ?? "",
      userId: userData.id,
      externalUserEmail: "",
      externalUserName: "",
      externalUserPhone: "",
      role: "Project Manager",
      isApprover: false,
      isActive: true,
    };

    OpportunityService.createOpportunityPlanContacts(body)
      .then((response: any) => {
        setLoading(false);
        commonService.showMessage(response?.data || {});
        if (response?.data?.isSuccess) {
          closeModal(true);
          if(props?.fromResourceModal) {
            const data = {
              project: response?.data?.result?.projectId || "",
              plan: response?.data?.result?.opportunityPlanId || "",
              step: ""
            }
            props?.refreshProject(data);
          }
        }
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const onInputChange = (planModel: OpportunityPlanItem) => setPlan(planModel);

  const onCheckingError = (isError: boolean) => setHasError(isError);

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 mb-10 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              {props?.isEdit ? `Edit Plan:  ${editPlanName}` : "Add Plan"}
            </Dialog.Title>
            <div className="mt-2">
              <div>
                <PlanForm plan={plan} isEdit={props?.isEdit} onInputChange={onInputChange} onCheckError={onCheckingError} show={props?.show}
                 project={props?.project as ProjectItem} onSetSeq={setSeq} checkingError={errorCheck} onCheckingError={onErrorCheck} />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => createPlan()}
          >
            <Spinner show={loading} />
            {props?.isEdit ? "Update" : "Create"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
