import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import commonService from "../../../app/service/commonService";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal?: any;
  date: string;
  chartData: DailyCategoryBurndownResult[];
}

export default function InvoiceBreakdownDetailsModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [filterList, setFilterList] = useState<
    Array<{ name: string; value: number }>
  >([]);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
    }
  }, [props.show]);

  const init = () => {
    setFromDate(props?.date);
    setToDate(props?.date);
    setFilterList([]);
    filter(props?.date, props?.date);
  };

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const filter = (startDate: string, endDate: string) => {
    let currentFilterData: any = {};
    if (startDate && endDate) {
      if (startDate === endDate) {
        const selectedData = (props.chartData || []).find(
          (x) => x.date?.split("T")[0] === startDate
        );
        currentFilterData = JSON.parse(
          JSON.stringify(selectedData?.data || {})
        );
      } else {
        (props.chartData || []).forEach((x) => {
          const currentDate = new Date(x.date?.split("T")[0]).getTime();
          if (
            new Date(startDate).getTime() <= currentDate &&
            new Date(endDate).getTime() >= currentDate
          ) {
            if (Object.keys(currentFilterData).length > 0) {
              Object.keys(x.data || {}).forEach((o) => {
                if (currentFilterData.hasOwnProperty(o)) {
                  currentFilterData[o] += x.data[o] || 0;
                } else {
                  currentFilterData[o] = x.data[o];
                }
              });
            } else {
              currentFilterData = JSON.parse(JSON.stringify(x.data || {}));
            }
          }
        });
      }
      const listItems = Object.keys(currentFilterData || {}).map((x) => ({
        name: x,
        value:
          parseFloat(
            (commonService.fixDecimal(currentFilterData[x]) || 0)?.toString()
          ) || 0,
      }));
      setFilterList(listItems);
    }
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Daily Invoice Breakdown Details
            </Dialog.Title>
            <div className="mt-2">
              <div className="w-96 flex items-end">
                <div className="sm:col-span-3 mr-3">
                  <label
                    htmlFor="fromDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Start On
                  </label>
                  <div className="mt-2">
                    <input
                      id="fromDate"
                      name="fromDate"
                      type="date"
                      required
                      value={fromDate}
                      onChange={(e) => setFromDate(e?.target?.value)}
                      className={commonService.classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        !fromDate
                          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                          : ""
                      )}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3 mr-3">
                  <label
                    htmlFor="toDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Due On
                  </label>
                  <div className="mt-2">
                    <input
                      id="toDate"
                      name="toDate"
                      type="date"
                      required
                      value={toDate}
                      onChange={(e) => setToDate(e?.target?.value)}
                      className={commonService.classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        !toDate
                          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                          : ""
                      )}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3 mr-3">
                  <button
                    type="button"
                    disabled={!fromDate || !toDate}
                    className={commonService.classNames(
                      "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                      !fromDate || !toDate ? "disabled:opacity-75" : ""
                    )}
                    onClick={() => filter(fromDate, toDate)}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-2 grid grid-cols-2 gap-6">
              <div className="h-full overflow-y-auto max-h-[50vh] flex-auto">
                {(filterList || [])?.length > 0 ? (
                  <table className="mt-2 w-full whitespace-nowrap text-left text-sm leading-6">
                    <thead className="border-b border-gray-200 text-gray-900">
                      <tr>
                        <th scope="col" className="px-0 py-3 font-semibold">
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(filterList || [])?.map((item) => (
                        <tr
                          key={item.name}
                          className="border-b border-gray-100"
                        >
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-900">
                            {item.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {item.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <button
                    type="button"
                    className="mt-2 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                      No data found.
                    </span>
                  </button>
                )}
              </div>
              <div
                className="flex-auto"
                style={{ width: "100%", height: "100%" }}
              >
                <ResponsiveContainer>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={filterList || []}
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {(filterList || []).map((entry, index) => (
                        <Cell
                          key={`cell-${entry.name}`}
                          fill={
                            commonService.coldColors500[
                              index % commonService.coldColors500.length
                            ]
                          }
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
