import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { SortTableHeader, TabsView } from "../../app/common/commonControls";
import { ErrorsTooltip, RecommendationsTooltip } from "../../app/common/tooltipControl";
import Pulse from "../../app/pulse/pulse";
import CloudResourceService from "../../app/service/cloudResourceService";
import commonService from "../../app/service/commonService";
import OpportunityService from "../../app/service/opportunityService";
import CloudResourcesModal from "../initiatives/modal/cloudResourceModal";
import FilterModal from "../initiatives/modal/filterModal";
import StorageAccountModal from "../initiatives/modal/storageAccountModal";
import StorageCostBreakDownModal from "../initiatives/modal/storageCostBreakdownModal";
import UnattachedStorageInvoiceModal from "../initiatives/modal/unattachedStorageInvoiceModal";
import VMDetailModal from "../initiatives/modal/vmModal";
import VMRightSizeModal from "../initiatives/modal/vmRightSize";

export default function CloudResources() {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUnattachedStorage, setLoadingUnattachedStorage] = useState<boolean>(false);
  const [loadingStorageAccount, setLoadingStorageAccount] = useState<boolean>(false);
  const [vmData, setVmData] = useState<any[]>([]);
  const [vmFilteredData, setVmFilteredData] = useState<any[]>([]);
  const lookBackList: number[] = [1, 7, 15, 30, 60, 90];
  const timeFrameList: Array<{timeFrame: string, value: number}> = [
    {timeFrame : '1 Hour', value: 1},
    {timeFrame : '1 Day', value: 24},
    {timeFrame : '1 Week', value: 168},
    {timeFrame : '1 Month', value: 720},
    {timeFrame : '1 Year', value: 8640}
  ];
  const commitmentPeriodsList: Array<{name: string, value: string}> = [
    {name : '1 YRS', value: 'license1Year'},
    {name : '3 YRS', value: 'license3Year'}
  ];
  const [timeFrame, setTimeFrame] = useState<number>(timeFrameList[0].value);
  const [commitmentPeriod, setCommitmentPeriod] = useState<string>(commitmentPeriodsList[1].value);
  const [lookBackPeriod, setLookBackPeriod] = useState<number>(30);

  const all = "All";

  const [hasFilter, setHasFilter] = useState<boolean>(false);

  const tabs = [
    { name: 'Virtual Machine', href: '#', current: true },
    { name: 'Waste', href: '#', current: false },
    { name: 'Storage', href: '#', current: false }
  ];

  const [currentTabSelection, setCurrentTabSelection] = useState<string>(
    tabs[0]?.name
  );

  const [unattachedStorage, setUnattachedStorage] = useState<AzureGenericResourceItem[]>([]);
  const [storageAccounts, setStorageAccounts] = useState<any[]>([]);
  const [storageAccountsFilteredData, setStorageAccountsFilteredData] = useState<any[]>([]);

  useEffect(() => {
    setCurrentTabSelection(tabs[0].name);
    getFinOpsSummaryVMs(lookBackPeriod);
    getListUnattachedDisk();
    getListStorageAccounts(lookBackPeriodForStorage);
    getPlanResourceTaskTemplate();
  }, []);

  const getFinOpsSummaryVMs = (lookBackPeriodData: number) => {
    setLoading(true);
    const customerId = commonService.getAccountId();
    CloudResourceService.getFinOpsSummaryVMs(
      customerId,
      lookBackPeriodData
    )
      .then((response: any) => {
        setLoading(false);
        const result = response?.data?.result || [];
        setVmData(result);
        setVmFilteredData(result);
        setFilters(result);
        setTopFilters(result, timeFrame, commitmentPeriod);
    })
    .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
  };

  const getListUnattachedDisk = () => {
    setLoadingUnattachedStorage(true);
    if(startDate && endDate) {
    const customerId = commonService.getAccountId();
    const request: AzureUnattachedDiskRequest = {
      customerId: customerId,
      startDate: startDate,
      endDate: endDate
    }
    CloudResourceService.getListUnattachedDisk(
      request
    )
      .then((response: any) => {
        setLoadingUnattachedStorage(false);
        const result = response?.data?.result || [];
        setUnattachedStorage(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoadingUnattachedStorage(false);
      });
    }
  };

  const parseStorageAccounts = (data: any) => {
    data.forEach((x: any) => {
      const invoiceData = x.invoiceInfo || [];
      let cost = 0;
      let tooltipVal: any = {};
      invoiceData.forEach((i: any) => {
        cost += i.cost;
        const meterName = i?.meterName || '';
        if(tooltipVal?.hasOwnProperty(meterName)) {
          tooltipVal[meterName] += i.cost;
        } else {
          tooltipVal[meterName] = i.cost;
        }
      });
      x.costVal = commonService.fixDecimal(cost);
      x.tooltipVal = tooltipVal;
    });
    return data;
  }

  const getListStorageAccounts = (lookBackPeriodVal: number) => {
    setLoadingStorageAccount(true);
    const customerId = commonService.getAccountId();
    const request: AzureStorageAccountsRequest = {
      customerId: customerId,
      lookBackPeriodDays: lookBackPeriodVal,
      resetCache: false
    }
    CloudResourceService.getListStorageAccounts(
      request
    )
      .then((response: any) => {
        setLoadingStorageAccount(false);
        const result = parseStorageAccounts(response?.data?.result || []);
        setStorageAccounts(result);
          setStorageAccountsFilteredData(result);
          setFiltersForStorage(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoadingStorageAccount(false);
      });
  };

  const onChangeLookback = (e: any) => {
    setLookBackPeriod(Number(e?.target?.value));
    getFinOpsSummaryVMs(Number(e?.target?.value));
  };

  const onChangeLookbackForStorage = (e: any) => {
    setLookBackPeriodForStorage(Number(e?.target?.value));
    getListStorageAccounts(Number(e?.target?.value));
  };

  const [showVMDetailModal, setShowVMDetailModal] = useState<boolean>(false);
  const [selectedResourceId, setSelectedResourceId] = useState<string>('');
  const [subscriptionList, setSubscriptionList] = useState<Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}>>([]);
  const [subscription, setSubscription] = useState<string>(all);
  const [resourceGroupList, setResourceGroupList] = useState<string[]>([]);
  const [resourceGroup, setResourceGroup] = useState<string>(all);


  const [timeFrameForStorage, setTimeFrameForStorage] = useState<number>(timeFrameList[0].value);
  const [commitmentPeriodForStorage, setCommitmentPeriodForStorage] = useState<string>(commitmentPeriodsList[1].value);
  const [lookBackPeriodForStorage, setLookBackPeriodForStorage] = useState<number>(30);
  const [subscriptionListForStorage, setSubscriptionListForStorage] = useState<Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}>>([]);
  const [subscriptionForStorage, setSubscriptionForStorage] = useState<string>(all);
  const [resourceGroupListForStorage, setResourceGroupListForStorage] = useState<string[]>([]);
  const [resourceGroupForStorage, setResourceGroupForStorage] = useState<string>(all);

  const openVMDetailModal = (resourceId: string) => {
    const type = resourceId?.split('/')[6];
    if(type === 'Microsoft.Compute') {
      setSelectedResourceId(resourceId);
      setShowVMDetailModal(true);
    }
  }

  const closeVMDetailModal = () => {
    setShowVMDetailModal(false);
  };

  const onChangeTimeFrame = (e: any) => {
    setTimeFrame(e?.target?.value);
    setTopFilters(vmFilteredData, Number(e?.target?.value), commitmentPeriod);
  }

  const onChangeTimeFrameForStorage = (e: any) => {
    setTimeFrameForStorage(e?.target?.value);
  }

  const onChangeCommitmentPeriod = (e: any) => {
    setCommitmentPeriod(e?.target?.value);
    setTopFilters(vmFilteredData, timeFrame, e?.target?.value);
  }

  const onChangeCommitmentPeriodForStorage = (e: any) => {
    setCommitmentPeriodForStorage(e?.target?.value);
  }

  const setFiltersForStorage = (data: any[]) => {
    const subscriptions: Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}> = [{ subscriptionName: all, subscriptionId: all, rgList: [all] }];
    (data || []).forEach(x => {
      const resourceGroupName = x.resourceId?.split('/')[4] || '';
      if(subscriptions[0].rgList.indexOf(resourceGroupName) === -1) {
        subscriptions[0].rgList.push(resourceGroupName);
      }
      const i = subscriptions.findIndex(s => s.subscriptionId === x.subscriptionId);
      if(i === -1) {
        subscriptions.push({ subscriptionName: x.subscription?.name, subscriptionId: x.subscriptionId, rgList: [all, resourceGroupName] });
      } else {
        if((subscriptions[i].rgList || []).indexOf(resourceGroupName) === -1) {
          subscriptions[i].rgList.push(resourceGroupName);
        }
      }
    });
    setSubscriptionListForStorage(subscriptions);
    setResourceGroupListForStorage(subscriptions[0].rgList);
    setSubscriptionForStorage(all);
    setResourceGroupForStorage(all);
  }

  const getRunTimeHoursPercentage = (vm: any) => {
    return parseFloat(commonService.fixDecimal(((vm.runTimeHours || 0) / (vm.lookBackPeriodHours || 0)) * 100 || 0));
  }

  const setFilters = (data: any[]) => {
    const maxData: any = initFiltersMax();
    const subscriptions: Array<{subscriptionName: string, subscriptionId: string, rgList: string[]}> = [{ subscriptionName: all, subscriptionId: all, rgList: [all] }];
    (data || []).forEach(x => {
      if(subscriptions[0].rgList.indexOf(x.resourceGroup) === -1) {
        subscriptions[0].rgList.push(x.resourceGroup);
      }
      const i = subscriptions.findIndex(s => s.subscriptionId === x.subscription?.id);
      if(i === -1) {
        subscriptions.push({ subscriptionName: x.subscription?.name, subscriptionId: x.subscription?.id, rgList: [all, x.resourceGroup] });
      } else {
        if((subscriptions[i].rgList || []).indexOf(x.resourceGroup) === -1) {
          subscriptions[i].rgList.push(x.resourceGroup);
        }
      }
      maxData.percentageMax = 100;
      const recommendationsLength = (x?.recommendations || []).length;
      if(recommendationsLength > maxData.recommendationsCountMax) {
        maxData.recommendationsCountMax = recommendationsLength;
      }
      const errorsLength = (x?.errors || []).length;
      if(errorsLength > maxData.errosCountMax) {
        maxData.errosCountMax = errorsLength;
      }
      const maxTimeFrame = 8640 * 3;
      const hardwareCurrent = parseFloat(commonService.fixDecimal((x?.hardwareCommitent?.hardwarePaygCost?.retailPrice || 0) * maxTimeFrame));
      if(hardwareCurrent > maxData.hardwareCurrentMax) {
        maxData.hardwareCurrentMax = hardwareCurrent;
      }

      const reservationSavings = parseFloat(commonService.fixDecimal((x?.hardwareCommitent?.savingsPerHour || 0) * maxTimeFrame));
      if(reservationSavings > maxData.reservationSavingsMax) {
        maxData.reservationSavingsMax = reservationSavings;
      }

      maxData.reservationBreakEvenMax = 100;

      const hybridBenefisSavingsPerHour = x?.hybridBenefis?.license3Year?.savingsPerHour; 
      const licencingCurrent = parseFloat(commonService.fixDecimal((x?.hybridBenefis?.osPaygCost || 0) * maxTimeFrame));
      if(licencingCurrent > maxData.licensingCurrentMax) {
        maxData.licensingCurrentMax = licencingCurrent;
      }

      const purchaseSavings = parseFloat(commonService.fixDecimal((hybridBenefisSavingsPerHour || 0) * maxTimeFrame));
      if(purchaseSavings > maxData.licensingSavingsMax) {
        maxData.licensingSavingsMax = purchaseSavings;
      }
      maxData.licensingBreakEvenMax = 100;
      let diskSize = x?.disk?.osDisk?.size || 0;
      (x.disk?.dataDisks || []).forEach((d: any) => {
        if((d.size || 0) > diskSize) {
          diskSize = d.size || 0;
        }
      });
      if(diskSize > maxData.diskSizeMax) {
        maxData.diskSizeMax = diskSize;
      }
    });
    setFilterMaxData(maxData);
    initDefaultFilters(maxData);
    setSubscriptionList(subscriptions);
    setResourceGroupList(subscriptions[0].rgList);
    setSubscription(all);
    setResourceGroup(all);
  }

  const initDefaultFilters = (maxData: any) => {
    const data = initFilters();
    data.percentageTo = maxData?.percentageMax || 0;
    data.recommendationsToCount = maxData?.recommendationsCountMax || 0;
    data.errosToCount = maxData?.errosCountMax || 0;
    data.hardwareCurrentTo = maxData?.hardwareCurrentMax || 0;
    data.reservationSavingsTo = maxData?.reservationSavingsMax || 0;
    data.reservationBreakEvenTo = maxData?.reservationBreakEvenMax || 0;
    data.licensingCurrentTo = maxData?.licensingCurrentMax || 0;
    data.licensingSavingsTo = maxData?.licensingSavingsMax || 0;
    data.licensingBreakEvenTo = maxData?.licensingBreakEvenMax || 0;
    data.diskSizeTo = maxData?.diskSizeMax || 0;
    setFilterData(data);
  }

  const onChangeSubscription = (e: any) => {
    setSubscription(e?.target?.value);
    filterResourceGroup(e?.target?.value);
    setResourceGroup(all);
    filterVM(filterData, e?.target?.value, all, headFilters, true);
  }

  const onChangeSubscriptionForStorage = (e: any) => {
    setSubscriptionForStorage(e?.target?.value);
    filterResourceGroupForStorage(e?.target?.value);
    setResourceGroupForStorage(all);
    filterStorageAccount(e?.target?.value, all);
  }

  const filterResourceGroup = (sub: string) => {
    const selectedResourceGroup = subscriptionList.find(x => x.subscriptionId === sub)?.rgList || [];
    setResourceGroupList(selectedResourceGroup);
  }

  const filterResourceGroupForStorage = (sub: string) => {
    const selectedResourceGroup = subscriptionList.find(x => x.subscriptionId === sub)?.rgList || [];
    setResourceGroupListForStorage(selectedResourceGroup);
  }

  const onChangeResourceGroup = (e: any) => {
    setResourceGroup(e?.target?.value);
    filterVM(filterData, subscription, e?.target?.value, headFilters, true);
  }

  const onChangeResourceGroupForStorage = (e: any) => {
    setResourceGroupForStorage(e?.target?.value);
    filterStorageAccount(subscriptionForStorage, e?.target?.value);
  }

  const nameSortList: NameValuePair[] = [
    {name: 'Name', value: 'name'},
    {name: 'Location', value: 'location'},
    {name: 'Size', value: 'size'},
    {name: 'Operating System', value: 'operativeSystem'}
  ];
  const runTimeList: NameValuePair[] = [
    {name: 'Percentage', value: 'percentage'},
    {name: 'Recommendations', value: 'recommendations'},
    {name: 'Errors', value: 'errors'}
  ];
  const hardwareList: NameValuePair[] = [
    {name: 'Current', value: 'hardwareCurrent'},
    {name: 'Res Savings', value: 'hardwareResSavings'}
  ];
  const licenseList: NameValuePair[] = [
    {name: 'Current', value: 'linenseCurrent'},
    {name: 'Pur Savings', value: 'lincensePurSavings'}
  ];
  const diskList: NameValuePair[] = [
    {name: 'Type', value: 'diskType'},
    {name: 'Size', value: 'diskSize'}
  ];

  const [sortBy, setSortBy] = useState<string>('');
  const [asc, setAsc] = useState<boolean>(false);

  const isSorted = (name: string) => {
    return name === sortBy;
  }

  const sortClick = (name: string) => {
    if(name !== sortBy) {
      setSortBy(name);
      setAsc(true);
    } else {
      setAsc(!asc);
    }
    sortData();
  }

  const sortData = () => {
    setLoading(true);
    const sortedData = (vmFilteredData || []).sort((a: any, b: any) => {
      let nameA = null;
      let nameB = null;
      if(sortBy === 'percentage') {
        nameA = getRunTimeHoursPercentage(a);
        nameB = getRunTimeHoursPercentage(b);
      } else if(sortBy === 'recommendations') {
        nameA = (a.recommendations || []).length || 0;
        nameB = (b.recommendations || []).length || 0;
      } else if(sortBy === 'errors') {
        nameA = (a.errors || []).length || 0;
        nameB = (b.errors || []).length || 0;
      } else if(sortBy === 'hardwareCurrent') {
        nameA = parseFloat(hardwareCurrentPrice(a, timeFrame)) || 0;
        nameB = parseFloat(hardwareCurrentPrice(b, timeFrame)) || 0
      } else if(sortBy === 'hardwareResSavings') {
        nameA = hardwareSavings(a, commitmentPeriod, timeFrame) || 0;
        nameB = hardwareSavings(b, commitmentPeriod, timeFrame) || 0;
      } else if(sortBy === 'linenseCurrent') {
        nameA = a?.hybridBenefis?.osPaygCost || 0;
        nameB = b?.hybridBenefis?.osPaygCost || 0;
      } else if(sortBy === 'lincensePurSavings') {
        if(is1Year()) {
          nameA = a?.hybridBenefis?.license1Year?.savingsPerHour || 0;
          nameB = b?.hybridBenefis?.license1Year?.savingsPerHour || 0;
        } else {
          nameA = a?.hybridBenefis?.license3Year?.savingsPerHour || 0;
          nameB = b?.hybridBenefis?.license3Year?.savingsPerHour || 0;
        }
        
      } else if(sortBy === 'diskType') {
        nameA = (a?.disk?.dataDisks || []).length || 0;
        nameB = (b?.disk?.dataDisks || []).length || 0;
      } else if(sortBy === 'diskSize') {
        nameA = a?.disk?.osDisk?.size || 0;
        nameB = b?.disk?.osDisk?.size || 0;
      } else {
        nameA = a[sortBy]?.toString()?.toLowerCase();
        nameB = b[sortBy]?.toString()?.toLowerCase();
      }
      
      if(asc) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
      
  });
  setVmFilteredData(sortedData);
  setLoading(false);
  }

  const initFilters = () => {
    return {
      name: '',
      location: '',
      size: '',
      os: '',
      percentageFrom: 0,
      percentageTo: 0,
      recommendationsFromCount: 0,
      recommendationsToCount: 0,
      errosFromCount: 0,
      errosToCount: 0,
      hardwareCurrentFrom: 0,
      hardwareCurrentTo: 0,
      reservationSavingsFrom: 0,
      reservationSavingsTo: 0,
      reservationBreakEvenFrom: 0,
      reservationBreakEvenTo: 0,
      licensingCurrentFrom: 0,
      licensingCurrentTo: 0,
      licensingSavingsFrom: 0,
      licensingSavingsTo: 0,
      licensingBreakEvenFrom: 0,
      licensingBreakEvenTo: 0,
      diskType: '',
      diskSizeFrom: 0,
      diskSizeTo: 0
    }
  }

  const initFiltersMax = () => {
    return {
      percentageMax: 0,
      recommendationsCountMax: 0,
      errosCountMax: 0,
      hardwareCurrentMax: 0,
      reservationSavingsMax: 0,
      reservationBreakEvenMax: 0,
      licensingCurrentMax: 0,
      licensingSavingsMax: 0,
      licensingBreakEvenMax: 0,
      diskSizeMax: 0
    }
  }

  const [filterData, setFilterData] = useState<any>(initFilters());
  const [filterMaxData, setFilterMaxData] = useState<any>(initFiltersMax());

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const openFilterModal = () => {
    setShowFilterModal(true);
  }

  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const refreshDetails = (data: any) => {
    setFilterData(data);
    filterVM(data, subscription, resourceGroup, headFilters, true);
    const filterList = Object.values(data || {});
    setHasFilter(filterList.some((x: any) => x?.toString()?.trim()?.length > 0));
  }

  const isValidNumber = (val: any) => {
    return val?.toString()?.trim()?.length > 0 && val != 0;
  }

  const [loadingTable, setLoadingTable] = useState<boolean>(false);

  const filterVM = (data: any, currentSub: string, currentRG: string, headFiltersData: any, fromFilterChange?: boolean) => {
    setLoadingTable(true);
    setTimeout(() => {
      const filterVMData: any[] = [];
    const currentVMData = JSON.parse(JSON.stringify(vmData || []));
    currentVMData.forEach((x: any) => {
      const percentage = getRunTimeHoursPercentage(x);
      const recommendationsLength = (x?.recommendations || []).length;
      const errorsLength = (x?.errors || []).length;
      const hardwareCurrent = parseFloat(hardwareCurrentPrice(x, timeFrame));
      const reservationSavings = hardwareSavings(x, commitmentPeriod, timeFrame);
      const reservationBreakEven = parseFloat(breakEvenRunTimePercentage(x, commitmentPeriod));
      const hybridBenefisSavingsPerHour = is1Year() ? x?.hybridBenefis?.license1Year?.savingsPerHour: x?.hybridBenefis?.license3Year?.savingsPerHour; 
      const hybridBenefisBreakEvenRunTimePercentage = is1Year() ? x?.hybridBenefis?.license1Year?.breakEvenRunTimePercentage: x?.hybridBenefis?.license3Year?.breakEvenRunTimePercentage;
      const licencingCurrent = parseFloat(commonService.fixDecimal((x?.hybridBenefis?.osPaygCost || 0) * timeFrame));
      const purchaseSavings = parseFloat(commonService.fixDecimal(hybridBenefisSavingsPerHour || 0));
      const purchaseBreakEven = parseFloat(commonService.fixDecimal((hybridBenefisBreakEvenRunTimePercentage || 0) * 100));
      
      if((!headFiltersData[0].selected || reservationBreakEven < percentage) &&
      (!headFiltersData[1].selected || (purchaseBreakEven < percentage && !x?.hybridBenefis?.currentSetting && 
        !(x?.hybridBenefis?.isHbuWindowsCandidate === "No" || !x?.hybridBenefis?.isHbuWindowsCandidate))) &&
      (!data?.name || x.name?.toString()?.toLowerCase().includes(data?.name?.toString()?.toLowerCase())) &&
      (!data?.location || data?.location === x.location) &&
      (!data?.size || data?.size === commonService.sizePipe(x.size)) &&
      (!data?.os || data?.os === x.operativeSystem) &&
      (!isValidNumber(data?.percentageFrom) || parseFloat(data?.percentageFrom) <= percentage) &&
      (!isValidNumber(data?.percentageTo) || parseFloat(data?.percentageTo) >= percentage) &&
      (!isValidNumber(data?.recommendationsFromCount) || parseFloat(data?.recommendationsFromCount) <= recommendationsLength) &&
      (!isValidNumber(data?.recommendationsToCount) || parseFloat(data?.recommendationsToCount) >= recommendationsLength) &&
      (!isValidNumber(data?.errosFromCount) || parseFloat(data?.errosFromCount) <= errorsLength) &&
      (!isValidNumber(data?.errosToCount) || parseFloat(data?.errosToCount) >= errorsLength) &&
      (!isValidNumber(data?.hardwareCurrentFrom) || parseFloat(data?.hardwareCurrentFrom) <= hardwareCurrent) &&
      (!isValidNumber(data?.hardwareCurrentTo) || parseFloat(data?.hardwareCurrentTo) >= hardwareCurrent) &&
      (!isValidNumber(data?.reservationSavingsFrom) || parseFloat(data?.reservationSavingsFrom) <= reservationSavings) &&
      (!isValidNumber(data?.reservationSavingsTo) || parseFloat(data?.reservationSavingsTo) >= reservationSavings) &&
      (!isValidNumber(data?.reservationBreakEvenFrom) || parseFloat(data?.reservationBreakEvenFrom) <= reservationBreakEven) &&
      (!isValidNumber(data?.reservationBreakEvenTo) || parseFloat(data?.reservationBreakEvenTo) >= reservationBreakEven) &&
      (!isValidNumber(data?.licensingCurrentFrom) || parseFloat(data?.licensingCurrentFrom) <= licencingCurrent) &&
      (!isValidNumber(data?.licensingCurrentTo) || parseFloat(data?.licensingCurrentTo) >= licencingCurrent) &&
      (!isValidNumber(data?.licensingSavingsFrom) || parseFloat(data?.licensingSavingsFrom) <= purchaseSavings) &&
      (!isValidNumber(data?.licensingSavingsTo) || parseFloat(data?.licensingSavingsTo) >= purchaseSavings) &&
      (!isValidNumber(data?.licensingBreakEvenFrom) || parseFloat(data?.licensingBreakEvenFrom) <= purchaseBreakEven) &&
      (!isValidNumber(data?.licensingBreakEvenTo) || parseFloat(data?.licensingBreakEvenTo) >= purchaseBreakEven) &&
      (!data?.diskType || (data?.diskType === 'os' && x?.disk?.osDisk?.sku) || (data?.diskType === 'data' && (x?.disk?.dataDisks || [])?.length >0)) &&
      (!isValidNumber(data?.diskSizeFrom) || parseFloat(data?.diskSizeFrom) <= x?.disk?.osDisk?.size) &&
      (!isValidNumber(data?.diskSizeTo) || parseFloat(data?.diskSizeTo) >= x?.disk?.osDisk?.size) &&
      ((currentSub === all && currentRG === all) || (currentSub === all && currentRG === x.resourceGroup) || 
      (currentRG === all && currentSub === x.subscription?.id) || (currentSub === x.subscription?.id && currentRG === x.resourceGroup))) {
        filterVMData.push(x);
      }
    });
    setVmFilteredData(filterVMData);
    if(fromFilterChange) {
      setTopFilters(filterVMData, timeFrame, commitmentPeriod);
    }
    setLoadingTable(false);
    }, 100);
  }

  const filterStorageAccount = (currentSub: string, currentRG: string) => {
    const filterStorageAccountData: any[] = [];
    const currentStorageAccountMData = JSON.parse(JSON.stringify(storageAccounts || []));
    currentStorageAccountMData.forEach((x: any) => {
      const resourceGroupName = x.resourceId?.split('/')[4] || '';
      if((currentSub === all && currentRG === all) || (currentSub === all && currentRG === resourceGroupName) || 
      (currentRG === all && currentSub === x.subscriptionId) || (currentSub === x.subscriptionId && currentRG === resourceGroupName)) {
        filterStorageAccountData.push(x);
      }
    });
    setStorageAccountsFilteredData(filterStorageAccountData);
  }

  const clearFilters = () => {
    initDefaultFilters(filterMaxData);
    setVmFilteredData(JSON.parse(JSON.stringify(vmData)));
    setSubscription(all);
    setResourceGroup(all);
    setHasFilter(false);
  }

  const is1Year = () => {
    return commitmentPeriod === 'license1Year';
  }

  const [showVMRightSizeModal, setShowVMRightSizeModal] = useState<boolean>(false);
  const [size, setSize] = useState<string>('');
  const [location, setLocation] = useState<string>('');

  const openVMRightSizeModal = (vm: any) => {
    const type = vm?.resourceId?.split('/')[6];
    if(type === 'Microsoft.Compute') {
      setSelectedResourceId(vm?.resourceId);
      setSize(vm?.size);
      setLocation(vm?.armLocation);
      setShowVMRightSizeModal(true);
    }
  }

  const closeVMRightSizeModal = () => {
    setShowVMRightSizeModal(false);
  };

  const [showInvoiceInfoModal, setShowInvoiceInfoModal] = useState<boolean>(false);
  const [invoiceInfo, setInvoiceInfo] = useState<any>([]);

  const openInvoiceInfoModal = (storageAccount: any) => {
    setInvoiceInfo(storageAccount?.invoiceInfo || []);
    setShowInvoiceInfoModal(true);
  }

  const closeInvoiceInfoModal = () => {
    setShowInvoiceInfoModal(false);
  }; 

  const [showStorageAccountModal, setShowStorageAccountModal] = useState<boolean>(false);
  const [selectedStorageAccount, setSelectedStorageAccount] = useState<any>({});

  const openStorageAccountModal = (storageAccount: any) => {
    setSelectedStorageAccount(storageAccount || {});
    setShowStorageAccountModal(true);
  }

  const closeStorageAccountModal = () => {
    setShowStorageAccountModal(false);
  }; 

  const [startDate, setStartDate] = useState<string>(commonService.getDate());
  const [endDate, setEndDate] = useState<string>(commonService.getDate());

  const reservationRetailPricePerYear = (vm: any, commitmentPeriodData: string) => {
    const reservationTerm = commitmentPeriodData === 'license1Year' ? '1 Year' : '3 Years';
    const retailPrice = (vm?.hardwareCommitent
      ?.reservationsPaygCost || []).find((x: any) => x.reservationTerm === reservationTerm)?.retailPrice || 0;
    return commitmentPeriodData === 'license1Year' ? retailPrice : retailPrice / 3;
  }

  const reservationPrice = (vm: any, commitmentPeriodData: string, timeFrameData: number) => {
    const price = commonService.fixDecimal(
      ((reservationRetailPricePerYear(vm, commitmentPeriodData) / 8640) * timeFrameData)
    );
    return price;
  }

  const hardwareCurrentPrice = (vm: any, timeFrameData: number) => {
    return commonService.fixDecimal(
      (vm?.hardwareCommitent?.hardwarePaygCost
        ?.retailPrice || 0) * timeFrameData
    )
  }
 
  const hardwareSavings = (vm: any, commitmentPeriodData: string, timeFrameData: number) => {
    const savings = parseFloat(hardwareCurrentPrice(vm, timeFrameData)) - parseFloat(reservationPrice(vm, commitmentPeriodData, timeFrameData));
    return parseFloat(commonService.fixDecimal(savings));
  }

  const breakEvenRunTimePercentage = (vm: any, commitmentPeriodData: string) => {
    const reservationTerm = commitmentPeriodData === 'license1Year' ? '1 Year' : '3 Years';
    const breakEvenRunTime = (vm?.hardwareCommitent
      ?.reservationsPaygCost || []).find((x: any) => x.reservationTerm === reservationTerm)?.breakEvenRunTimePercentage || 0;
    return commonService.fixDecimal(breakEvenRunTime * 100);
  }

  const filterList = [
    { name: 'Reservation', totalCost: 0, totalSavings: 0, count: 0, selected: false },
    { name: 'License', totalCost: 0, totalSavings: 0, count: 0, selected: false }
  ]
  const [headFilters, setHeadFilters] = useState<any[]>([]);

  const setTopFilters = (currentVMdata: any, timeFrameData: number, commitmentPeriodData: string) => {
    let totalReservationCost = 0;
    let totalReservationSavings = 0;
    let totalReservationCount = 0;
    let totalLicenseCost = 0;
    let totalLicenseSavings = 0;
    let totalLicenseCount = 0;
    currentVMdata.forEach((x: any) => {
      const reservationBreakEven = parseFloat(breakEvenRunTimePercentage(x, commitmentPeriodData));

      const licenseBreakEven = parseFloat(commonService.fixDecimal(((x?.hybridBenefis ?? {})[commitmentPeriodData]?.breakEvenRunTimePercentage || 0) * 100));

      const runTime = getRunTimeHoursPercentage(x);
      if(reservationBreakEven < runTime) {
        totalReservationCost += parseFloat(reservationPrice(x, commitmentPeriodData, timeFrameData) || '0');
        totalReservationSavings += hardwareSavings(x, commitmentPeriodData, timeFrameData);
        totalReservationCount++;
      }
      if(licenseBreakEven < runTime && !x?.hybridBenefis?.currentSetting && 
        !(x?.hybridBenefis?.isHbuWindowsCandidate ===
                                  "No" ||
                                !x?.hybridBenefis?.isHbuWindowsCandidate)) {
        totalLicenseCost += parseFloat(commonService.fixDecimal((x?.hybridBenefis?.osPaygCost || 0) * timeFrameData));
        totalLicenseSavings += parseFloat(commonService.fixDecimal(((x?.hybridBenefis ?? {})[commitmentPeriodData]?.savingsPerHour || 0) * timeFrameData));
        totalLicenseCount++;
      }

    });
    filterList[0].totalCost = parseFloat(commonService.fixDecimal(totalReservationCost));
    filterList[0].totalSavings = parseFloat(commonService.fixDecimal(totalReservationSavings));
    filterList[0].count = totalReservationCount;
    filterList[1].totalCost = parseFloat(commonService.fixDecimal(totalLicenseCost));
    filterList[1].totalSavings = parseFloat(commonService.fixDecimal(totalLicenseSavings));
    filterList[1].count = totalLicenseCount;
    setHeadFilters(filterList);
  }

  const selectFilter = (i: number) => {
    const filters = JSON.parse(JSON.stringify(headFilters));
    filters[i].selected = !filters[i].selected;
    setHeadFilters(filters);
    filterVM(filterData, subscription, resourceGroup, filters);
  }

  const checkbox = useRef<any>(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedResource, setSelectedResource] = useState<
    OpportunityPlanResourceItem[]
  >([]);

  const toggleAll = () => {
    setSelectedResource(checked || indeterminate ? [] : vmFilteredData || []);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const [templates, setTemplates] = useState<
    OpportunityPlanResourceTaskTemplateItem[]
  >([]);

  const getPlanResourceTaskTemplate = () => {
    setTemplates([]);
    OpportunityService.getPlanResourceTaskTemplate()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setTemplates(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const [showCloudResourceModal, setShowCloudResourceModal] = useState<boolean>(false);
  const [selectedResources, setSelectedResources] = useState<any[]>([]);

  const openCloudResourceModal = () => {
    setSelectedResources(selectedResource || []);
    setShowCloudResourceModal(true);
  }

  const closeCloudResourceModal = (refresh?: boolean) => {
    setShowCloudResourceModal(false);
    if(refresh) {
      getFinOpsSummaryVMs(lookBackPeriod);
      setSelectedResource([]);
    }
  }

  const [showStorageCostBreakdownModal, setShowStorageCostBreakdownModal] = useState<boolean>(false);

  const openStorageCostBreakdownModal = (storageAccount: any) => {
    setSelectedStorageAccount(storageAccount || {});
    setShowStorageCostBreakdownModal(true);
  }

  const closeStorageCostBreakdownModal = () => {
    setShowStorageCostBreakdownModal(false);
  }; 

  return (
    <>
      {showCloudResourceModal && (
        <CloudResourcesModal
          show={showCloudResourceModal}
          closeModal={closeCloudResourceModal}
          templates={templates || []}
          resources={selectedResources} />)
      }

    {showStorageCostBreakdownModal && (
        <StorageCostBreakDownModal
          show={showStorageCostBreakdownModal}
          closeModal={closeStorageCostBreakdownModal}
          storageAccount={selectedStorageAccount || {}} />
      )}
      {showStorageAccountModal && (
        <StorageAccountModal
          show={showStorageAccountModal}
          closeModal={closeStorageAccountModal}
          data={selectedStorageAccount || {}}
        />
      )}
      {showInvoiceInfoModal && (
        <UnattachedStorageInvoiceModal
          show={showInvoiceInfoModal}
          closeModal={closeInvoiceInfoModal}
          invoiceData={invoiceInfo || []}
        />
      )}
      {showVMDetailModal && (
        <VMDetailModal
          show={showVMDetailModal}
          closeModal={closeVMDetailModal}
          resourceId={selectedResourceId || ""}
        />
      )}
      {showVMRightSizeModal && (
        <VMRightSizeModal
          show={showVMRightSizeModal}
          closeModal={closeVMRightSizeModal}
          resourceId={selectedResourceId || ""}
          currentSize={size}
          location={location}
        />
      )}
      {showFilterModal && (
        <FilterModal
          show={showFilterModal}
          closeModal={closeFilterModal}
          filterData={filterData}
          refreshDetails={refreshDetails}
          resourceData={vmData}
          clearFilters={clearFilters}
          filterMaxData={filterMaxData}
        />
      )}
      <div className="py-2 mx-auto max-w-7xl px-4 sm:px-6 lg:px-2">
        <TabsView
          tabs={tabs}
          currentTabSelection={currentTabSelection}
          setCurrentTabSelection={setCurrentTabSelection}
        />

        <div
          className={commonService.classNames(
            currentTabSelection !== "Virtual Machine" ? "hidden" : "",
            "mt-2"
          )}
        >
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Virtual Machine
              </h1>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
                {loading ? (
                  <Pulse show={loading} />
                ) : (
                  <>
                    <div className="flex items-center">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="lookBackPeriod"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Loopback Period
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="lookBackPeriod"
                            name="lookBackPeriod"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={lookBackPeriod}
                            onChange={(e) => {
                              onChangeLookback(e);
                            }}
                          >
                            {lookBackList.map((range: number) => (
                              <option value={range} key={range}>
                                {range}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="TimeFrame"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Time Frame
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="TimeFrame"
                            name="TimeFrame"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={timeFrame}
                            onChange={(e) => {
                              onChangeTimeFrame(e);
                            }}
                          >
                            {timeFrameList.map((time: any) => (
                              <option value={time.value} key={time.value}>
                                {time.timeFrame}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="CommitmentPeriod"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Commitment Periods
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="CommitmentPeriod"
                            name="CommitmentPeriod"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={commitmentPeriod}
                            onChange={(e) => {
                              onChangeCommitmentPeriod(e);
                            }}
                          >
                            {commitmentPeriodsList.map((period: any) => (
                              <option value={period.value} key={period.value}>
                                {period.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="Subscriptions"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Subscriptions
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="Subscriptions"
                            name="Subscriptions"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={subscription}
                            onChange={(e) => {
                              onChangeSubscription(e);
                            }}
                          >
                            {subscriptionList.map(
                              (sub: {
                                subscriptionName: string;
                                subscriptionId: string;
                                rgList: string[];
                              }) => (
                                <option
                                  value={sub?.subscriptionId}
                                  key={sub?.subscriptionId}
                                >
                                  {sub?.subscriptionName}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="rsGroup"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          RG
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="rsGroup"
                            name="rsGroup"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={resourceGroup}
                            onChange={(e) => {
                              onChangeResourceGroup(e);
                            }}
                          >
                            {resourceGroupList.map((rg: string) => (
                              <option value={rg} key={rg}>
                                {rg}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => openFilterModal()}
                        >
                          Filter
                        </button>
                      </div>
                      {hasFilter && (
                        <div className="sm:px-6 lg:px-6">
                          <button
                            type="button"
                            className="block rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => clearFilters()}
                          >
                            Clear
                          </button>
                        </div>
                      )}
                      {selectedResource?.length > 0 && 
                      <div className="sm:px-6 lg:px-6">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => openCloudResourceModal()}
                        >
                          Resource
                        </button>
                      </div>}
                    </div>
                    <div className="mb-8 ml-6">
                      <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                        {headFilters.map((project, i) => (
                          <li
                            key={project.name}
                            className="cursor-pointer col-span-1 flex rounded-md shadow-sm"
                            onClick={() => selectFilter(i)}
                          >
                            <div
                              className={commonService.classNames(
                                "flex flex-1 items-center justify-between truncate rounded-md border bg-white border-2",
                                project.selected
                                  ? "border-indigo-600"
                                  : "border-gray-200"
                              )}
                            >
                              <div className="flex-1 truncate px-4 py-2 text-sm">
                                <a
                                  href="#"
                                  className="font-medium text-gray-900 hover:text-gray-600"
                                >
                                  {project.name}
                                </a>
                                <p className="text-gray-500">
                                  Total Count: {project.count} <br />
                                  Total Cost: ${project.totalCost} <br />
                                  Total Savings: ${project.totalSavings}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {loadingTable ? (
                      <Pulse show={loadingTable} />
                    ) : (
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="relative px-7 sm:w-12 sm:px-6"
                            >
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Name

                                <SortTableHeader current={isSorted("name") ||
                                    isSorted("location") ||
                                    isSorted("size") ||
                                    isSorted("operativeSystem")}
                                    asc={asc} 
                                    sortList={nameSortList || []}
                                    sortBy={sortBy}
                                    sortClick={sortClick}
                                    />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                RunTime Hours
                                <SortTableHeader current={isSorted("percentage") ||
                                    isSorted("recommendations") ||
                                    isSorted("errors")}
                                    asc={asc} 
                                    sortList={runTimeList || []}
                                    sortBy={sortBy}
                                    sortClick={sortClick}
                                    />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Hardware
                                <SortTableHeader current={isSorted("hardwareCurrent") ||
                                    isSorted("hardwareResSavings")}
                                    asc={asc} 
                                    sortList={hardwareList || []}
                                    sortBy={sortBy}
                                    sortClick={sortClick}
                                    />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Licensing
                                <SortTableHeader current={isSorted("linenseCurrent") ||
                                    isSorted("lincensePurSavings")}
                                    asc={asc} 
                                    sortList={licenseList || []}
                                    sortBy={sortBy}
                                    sortClick={sortClick}
                                    />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              <div className="flex items-center">
                                Disk
                                <SortTableHeader current={isSorted("diskType") ||
                                    isSorted("diskSize")}
                                    asc={asc} 
                                    sortList={diskList || []}
                                    sortBy={sortBy}
                                    sortClick={sortClick}
                                    />
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {(vmFilteredData || [])?.map((vm, i: number) => (
                            <tr
                              key={vm.id}
                              className={
                                selectedResource.includes(vm)
                                  ? "bg-gray-50"
                                  : undefined
                              }
                            >
                              <td className="relative px-7 sm:w-12 sm:px-6">
                                {selectedResource.includes(vm) && (
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                )}
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  value={vm.id}
                                  checked={selectedResource.includes(vm)}
                                  onChange={(e) =>
                                    setSelectedResource(
                                      e.target.checked
                                        ? [...selectedResource, vm]
                                        : selectedResource.filter(
                                            (p) => p !== vm
                                          )
                                    )
                                  }
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                <div>
                                  <a
                                    href="#"
                                    className="truncate font-medium text-gray-900"
                                    onClick={() =>
                                      openVMDetailModal(vm.resourceId)
                                    }
                                  >
                                    {vm.name}
                                  </a>
                                  <br />
                                  {vm.armLocation}
                                  <br />
                                  <a
                                    href="#"
                                    className="truncate text-gray-900"
                                    onClick={() => openVMRightSizeModal(vm)}
                                  >
                                    {commonService.sizePipe(vm.size || "")}
                                  </a>
                                  <br />
                                  {vm.operativeSystem}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                <div className="w-full bg-gray-200 rounded-full h-2.5">
                                  <div
                                    className="bg-indigo-600 h-2.5 rounded-full"
                                    style={{
                                      width: `${commonService.fixDecimal(
                                        ((vm.runTimeHours || 0) /
                                          (vm.lookBackPeriodHours || 0)) *
                                          100 || 0
                                      )}%`,
                                    }}
                                  ></div>
                                </div>
                                {`${commonService.fixDecimal(
                                  vm.runTimeHours || 0
                                )}/${
                                  vm.lookBackPeriodHours
                                } (${getRunTimeHoursPercentage(vm)}%)`}
                                <RecommendationsTooltip
                                  index={i}
                                  recommendations={vm?.recommendations || []}
                                />
                                <ErrorsTooltip
                                  index={i}
                                  errors={vm?.errors || []}
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                <div>
                                  Current: $
                                  {hardwareCurrentPrice(vm, timeFrame)} <br />
                                  {is1Year() ? "1Y" : "3Y"} Res:
                                  <span>
                                    $
                                    {reservationPrice(
                                      vm,
                                      commitmentPeriod,
                                      timeFrame
                                    )}
                                  </span>{" "}
                                  SAV:
                                  <span
                                    className={commonService.classNames(
                                      "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
                                      hardwareSavings(
                                        vm,
                                        commitmentPeriod,
                                        timeFrame
                                      ) > 0
                                        ? "bg-green-50 text-green-700 ring-green-600/20"
                                        : "bg-blue-50 text-blue-700 ring-blue-700/10"
                                    )}
                                  >
                                    {hardwareSavings(
                                      vm,
                                      commitmentPeriod,
                                      timeFrame
                                    )}
                                  </span>{" "}
                                  BE:
                                  <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                    {breakEvenRunTimePercentage(
                                      vm,
                                      commitmentPeriod
                                    )}
                                    %
                                  </span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                <div>
                                  {vm?.hybridBenefis?.isHbuWindowsCandidate ===
                                    "No" ||
                                  !vm?.hybridBenefis?.isHbuWindowsCandidate ? (
                                    "NA"
                                  ) : (
                                    <>
                                      {vm?.hybridBenefis?.currentSetting ? (
                                        <>
                                          Current: $
                                          {commonService.fixDecimal(
                                            (vm?.hybridBenefis?.osPaygCost ||
                                              0) * timeFrame
                                          )}{" "}
                                          ({vm?.hybridBenefis?.currentSetting})
                                        </>
                                      ) : (
                                        <dd
                                          data-tooltip-id={
                                            "-description-tooltip" + i
                                          }
                                        >
                                          <Tooltip
                                            id={"-description-tooltip" + i}
                                            arrowColor="transparent"
                                            place="bottom"
                                          >
                                            <div style={{}}>
                                              <span>
                                                Purchase License:{" "}
                                                {
                                                  (vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.licensesCommitment
                                                    ?.productTitle
                                                }
                                              </span>
                                              <br />
                                              <span>
                                                Term:{" "}
                                                {
                                                  (vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.licensesCommitment
                                                    ?.termDuration
                                                }
                                              </span>
                                              <br />
                                              <span>
                                                Cores Per License:{" "}
                                                {
                                                  (vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.licensesCommitment
                                                    ?.coresMax
                                                }
                                              </span>
                                              <br />
                                              <span>
                                                Licenses Needed:{" "}
                                                {
                                                  (vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.licenseNeededCount
                                                }
                                              </span>
                                              <br />
                                              <span>
                                                Payment Schedule:{" "}
                                                {
                                                  (vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.licensesCommitment
                                                    ?.billingPlan
                                                }
                                              </span>
                                              <br />
                                              <span>
                                                Total Cost:{" "}
                                                {((vm?.hybridBenefis || {})[
                                                  commitmentPeriod
                                                ]?.licenseNeededCount || 0) *
                                                  ((vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.licensesCommitment?.erp ||
                                                    0)}
                                              </span>
                                            </div>
                                          </Tooltip>
                                          Current: $
                                          {commonService.fixDecimal(
                                            (vm?.hybridBenefis?.osPaygCost ||
                                              0) * timeFrame
                                          )}{" "}
                                          <br />
                                          {is1Year() ? "1Y" : "3Y"} Pur: $
                                          {commonService.fixDecimal(
                                            ((vm?.hybridBenefis || {})[
                                              commitmentPeriod
                                            ]?.licenseNeededPricePerHour || 0) *
                                              timeFrame
                                          )}{" "}
                                          SAV:
                                          <span
                                            className={commonService.classNames(
                                              "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
                                              parseFloat(
                                                commonService.fixDecimal(
                                                  (vm?.hybridBenefis || {})[
                                                    commitmentPeriod
                                                  ]?.savingsPerHour || 0
                                                )
                                              ) > 0
                                                ? "bg-green-50 text-green-700 ring-green-600/20"
                                                : "bg-blue-50 text-blue-700 ring-blue-700/10"
                                            )}
                                          >
                                            {commonService.fixDecimal(
                                              ((vm?.hybridBenefis || {})[
                                                commitmentPeriod
                                              ]?.savingsPerHour || 0) *
                                                timeFrame
                                            )}
                                          </span>{" "}
                                          BE:
                                          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                            {commonService.fixDecimal(
                                              ((vm?.hybridBenefis || {})[
                                                commitmentPeriod
                                              ]?.breakEvenRunTimePercentage ||
                                                0) * 100
                                            )}
                                            %
                                          </span>
                                        </dd>
                                      )}
                                    </>
                                  )}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                {(!filterData?.diskType ||
                                  filterData?.diskType === "os") && (
                                  <dd data-tooltip-id={"-osDisk-tooltip" + i}>
                                    <span className="flex items-center">
                                      Os Disk -{" "}
                                      {commonService.sizePipe(
                                        vm?.disk?.osDisk?.sku || ""
                                      )}{" "}
                                      {vm?.disk?.osDisk?.size} GB
                                      {(vm?.disk?.osDisk?.recommendations || [])
                                        .length > 0 && (
                                        <InformationCircleIcon
                                          aria-hidden="true"
                                          className="w-4 h-4 ml-1"
                                        />
                                      )}
                                    </span>
                                    {(vm?.disk?.osDisk?.recommendations || [])
                                      .length > 0 && (
                                      <Tooltip
                                        id={"-osDisk-tooltip" + i}
                                        arrowColor="transparent"
                                        place="bottom"
                                      >
                                        <div style={{}}>
                                          {(
                                            vm?.disk?.osDisk?.recommendations ||
                                            []
                                          ).map((osDiskRecommendation: any) => (
                                            <>
                                              <span>
                                                Summary:{" "}
                                                {osDiskRecommendation?.summary}
                                              </span>{" "}
                                              <br />
                                              <span>
                                                Severity:{" "}
                                                {osDiskRecommendation?.severity}
                                              </span>{" "}
                                              <br />
                                              <hr />
                                            </>
                                          ))}
                                        </div>
                                      </Tooltip>
                                    )}
                                  </dd>
                                )}
                                {(!filterData?.diskType ||
                                  filterData?.diskType === "data") &&
                                  (vm?.disk?.dataDisks || []).map(
                                    (disk: any, d: number) => (
                                      <dd
                                        data-tooltip-id={
                                          "-dataDisk-tooltip" + i
                                        }
                                        key={"Data Disk" + d}
                                      >
                                        <span className="flex items-center">
                                          Data Disk{d + 1} -{" "}
                                          {commonService.sizePipe(
                                            disk?.sku || ""
                                          )}{" "}
                                          {disk?.size} GB
                                          {(disk?.recommendations || [])
                                            .length > 0 && (
                                            <InformationCircleIcon
                                              aria-hidden="true"
                                              className="w-4 h-4 ml-1"
                                            />
                                          )}
                                        </span>
                                        {(disk?.recommendations || [])?.length >
                                          0 && (
                                          <Tooltip
                                            id={"-dataDisk-tooltip" + i}
                                            arrowColor="transparent"
                                            place="bottom"
                                          >
                                            <div style={{}}>
                                              {(
                                                disk?.recommendations || []
                                              ).map(
                                                (
                                                  dataDiskRecommendation: any
                                                ) => (
                                                  <>
                                                    <span>
                                                      Summary:{" "}
                                                      {
                                                        dataDiskRecommendation?.summary
                                                      }
                                                    </span>{" "}
                                                    <br />
                                                    <span>
                                                      Severity:{" "}
                                                      {
                                                        dataDiskRecommendation?.severity
                                                      }
                                                    </span>{" "}
                                                    <br />
                                                    <hr />
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </Tooltip>
                                        )}
                                      </dd>
                                    )
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={commonService.classNames(
            currentTabSelection !== "Waste" ? "hidden" : "",
            "mt-2"
          )}
        >
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Waste
              </h1>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
                {loadingUnattachedStorage ? (
                  <Pulse show={loadingUnattachedStorage} />
                ) : (
                  <>
                    <div className="flex items-end">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="startDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Start Date
                        </label>
                        <div className="mt-2">
                          <input
                            id="startDate"
                            name="startDate"
                            type="date"
                            required
                            value={startDate}
                            onChange={(e) => setStartDate(e?.target?.value)}
                            className={commonService.classNames(
                              "w-72 mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              !startDate
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="endDate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          End Date
                        </label>
                        <div className="mt-2">
                          <input
                            id="endDate"
                            name="endDate"
                            type="date"
                            required
                            value={endDate}
                            onChange={(e) => setEndDate(e?.target?.value)}
                            className={commonService.classNames(
                              "w-72 mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              !endDate
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                      </div>
                      <div className="sm:px-6 lg:px-6">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={getListUnattachedDisk}
                        >
                          Filter
                        </button>
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-300 mt-2">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Location
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Resource Group
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            SKU
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Size
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Invoice Info
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {(unattachedStorage || [])?.map((item) => (
                          <tr key={item.id}>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.location}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.resourceGroup}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.skuName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item.skuSize}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              <a
                                href="#"
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={() => openInvoiceInfoModal(item)}
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={commonService.classNames(
            currentTabSelection !== "Storage" ? "hidden" : "",
            "mt-2"
          )}
        >
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Storage
              </h1>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-2">
                {loadingStorageAccount ? (
                  <Pulse show={loadingStorageAccount} />
                ) : (
                  <>
                    <div className="flex items-center">
                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="lookBackPeriodForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Loopback Period
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="lookBackPeriodForStorage"
                            name="lookBackPeriodForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={lookBackPeriodForStorage}
                            onChange={(e) => {
                              onChangeLookbackForStorage(e);
                            }}
                          >
                            {lookBackList.map((range: number) => (
                              <option value={range} key={range}>
                                {range}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="timeFrameForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Time Frame
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="timeFrameForStorage"
                            name="timeFrameForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={timeFrameForStorage}
                            onChange={(e) => {
                              onChangeTimeFrameForStorage(e);
                            }}
                          >
                            {timeFrameList.map((time: any) => (
                              <option value={time.value} key={time.value}>
                                {time.timeFrame}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="commitmentPeriodForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Commitment Periods
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="commitmentPeriodForStorage"
                            name="commitmentPeriodForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={commitmentPeriodForStorage}
                            onChange={(e) => {
                              onChangeCommitmentPeriodForStorage(e);
                            }}
                          >
                            {commitmentPeriodsList.map((period: any) => (
                              <option value={period.value} key={period.value}>
                                {period.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="subscriptionForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Subscriptions
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="subscriptionForStorage"
                            name="subscriptionForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={subscriptionForStorage}
                            onChange={(e) => {
                              onChangeSubscriptionForStorage(e);
                            }}
                          >
                            {subscriptionListForStorage.map(
                              (sub: {
                                subscriptionName: string;
                                subscriptionId: string;
                                rgList: string[];
                              }) => (
                                <option
                                  value={sub?.subscriptionId}
                                  key={sub?.subscriptionId}
                                >
                                  {sub?.subscriptionId}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="sm:px-6 lg:px-6">
                        <label
                          htmlFor="resourceGroupForStorage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          RG
                        </label>
                        <div className="mt-1 mb-4">
                          <select
                            id="resourceGroupForStorage"
                            name="resourceGroupForStorage"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={resourceGroupForStorage}
                            onChange={(e) => {
                              onChangeResourceGroupForStorage(e);
                            }}
                          >
                            {resourceGroupListForStorage.map((rg: string) => (
                              <option value={rg} key={rg}>
                                {rg}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-300 mt-2">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Finops
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Cost
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                          >
                            Invoice Info
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {(storageAccountsFilteredData || [])?.map((item, i) => (
                          <tr key={item.id}>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              <a
                                href="#"
                                className="truncate font-medium text-gray-900"
                                onClick={() => openStorageAccountModal(item)}
                              >
                                {item?.resourceData?.name}
                              </a>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              <p>Recommendations (0)</p>
                              {/* <p>Errors ({(item?.errors || []).length})</p> */}
                              <ErrorsTooltip
                                index={i}
                                errors={item?.errors || []}
                              />
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              <a className="flex items-center cursor-pointer"
                                data-tooltip-id={"-storage-cost-tooltip" + i}
                                href="#"
                                onClick={() => openStorageCostBreakdownModal(item)}
                              >
                                ${item.costVal}
                                <InformationCircleIcon
                                  aria-hidden="true"
                                  className="w-4 h-4 ml-1"
                                />
                              </a>
                              <Tooltip
                                id={"-storage-cost-tooltip" + i}
                                arrowColor="transparent"
                                place="bottom"
                              >
                                <div style={{}}>
                                  {(Object.keys(item.tooltipVal) || []).map(
                                    (tooltipVal: any) => (
                                      <>
                                        <span>
                                          {tooltipVal}: ${commonService.fixDecimal(item.tooltipVal[tooltipVal] || 0)}
                                        </span>{" "}
                                        <br />
                                      </>
                                    )
                                  )}
                                </div>
                              </Tooltip>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              <a
                                href="#"
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={() => openInvoiceInfoModal(item)}
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
