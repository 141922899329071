import { ChartCart } from '../../components/reports/chartCart';
import { MontlhyInvoiceReportChartAll } from '../../components/reports/montlhyInvoiceReportChartAll';
import ReportService from '../service/reportService';
import { useEffect, useState } from 'react';
import SummaryMonthCostCategoryComparison from '../../components/reports/summaryMonthCostCategoryComparison';
import Pulse from '../pulse/pulse';
import commonService from '../service/commonService';
import CloudResourceService from '../service/cloudResourceService';
import BudgetModal from '../../components/initiatives/modal/budgetModal';
import { toast } from 'react-toastify';
import OpportunityService from '../service/opportunityService';

const stats = [
    { name: 'Invoice', value: '', change: '', changeType: 'positive' },
    { name: 'Resources', value: '', change: '', changeType: 'positive' },
    { name: 'Budget', value: '$0', change: '10%', changeType: 'negative' },
    { name: 'Tag Compliance', value: '', change: '', changeType: 'positive' },
]



const getRandomValue = (base: number, percentage: number): number => {
    const variance = (base * percentage) / 100;
    const value = Math.round(base + Math.random() * (2 * variance) - variance);
    if (value < 0) {
        return 0;
    } else {
        return value;
    }
}

const generateCommitmentDataSet = (startDate: string, days: number): any[] => {
    const dataSet: any[] = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < days; i++) {
        const utilizationBase = 12000;  // Average of the example values
        const unUtilizeBase = 1195;    // Average of the example values
        const breakEvenBase = 9551;    // Average of the example values
        const overUtilizeBase = 1000;

        dataSet.push({
            date: currentDate.toISOString().split('T')[0],
            utilizationCost: getRandomValue(utilizationBase, 10),  // Vary by ±10%
            unUtilizeCost: getRandomValue(unUtilizeBase, 50),     // Vary by ±50%
            breakEvenCost: getRandomValue(breakEvenBase, 10),     // Vary by ±10%
            overUtilizeCost: getRandomValue(overUtilizeBase, 200)      // Vary by ±10%
        });

        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dataSet;
}

const CommitementDataSet = generateCommitmentDataSet('2023-07-01', 30);
const SavingsPlanDataSet = generateCommitmentDataSet('2023-07-01', 30);
const LicensesPlanDataSet = generateCommitmentDataSet('2023-07-01', 30);
const ComponentsReservation: any = {
    key: 'date',
    utilization: {
        color: 'rgb(59,130,246)',
        name: 'Utilize',
    },
    unUtilize: {
        color: 'rgb(59,130,246, .5)',
        name: 'Un-Utilize',
    },
    breakEven: {
        color: '#d946ef',
        name: 'Break Even',
    },
    overUtilize: {
        color: '#e11d48',
        name: 'Over Utilize',
    }
}

function aggregateElements(elements: MonthCostCategoryComparisonResult[]): number {
    let totalPercentage = 0;
    let totalCount = 0;

    for (let el of elements) {
        totalPercentage += el.tagCompliance * el.resourceCount; // Weighted sum of percentages
        totalCount += el.resourceCount;
    }

    return totalCount ? totalPercentage / totalCount : 0;
}

function aggregateElementsPrev(elements: MonthCostCategoryComparisonResult[]): number {
    let totalPercentage = 0;
    let totalCount = 0;

    for (let el of elements) {
        totalPercentage += el.prevTagCompliance * el.prevCost; // Weighted sum of percentages
        totalCount += el.resourceCount;
    }

    return totalCount ? totalPercentage / totalCount : 0;
}
function filterIfCostIsZero(item: MonthCostCategoryComparisonResult) {
    if (item.cost === 0 && item.prevCost === 0) {
        return false; // skip
    }
    return true;
}

const groupAndSumByCategoryAndRegion = (data: MonthCostCategoryComparisonResult[]): SummaryMonthCostCategoryComparisonModel[] => {
    const grouped = data.reduce<{ [key: string]: SummaryMonthCostCategoryComparisonModel }>((acc, item) => {
        const key = `${item.category}-${item.resourceLocation}`; // unique key based on Category and Region
        if (item.cost === 0 && item.prevCost === 0) return acc;
        if (!acc[key]) {
            acc[key] = {
                id: key,
                category: item.category,
                region: item.resourceLocation,
                cost: 0,
                prevCost: 0,
                items: []
            };
        }
        acc[key].cost += item.cost;
        acc[key].prevCost += item.prevCost;
        acc[key].items.push(item);
        return acc;
    }, {});

    return Object.values(grouped);
}

const formatDate = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Adding 1 because getUTCMonth() returns zero-based month
  const day = ('0' + date.getUTCDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

const getEndUtcDate = (): string => {
  return formatDate(new Date());
};

const getStartUtcDate = (): string => {
  const now = new Date();
  now.setUTCMonth(now.getUTCMonth() - 1);
  return formatDate(now);
};

export default function MonthlyInvoiceReport() {
    const [meterCategoryDataResult, setMeterCategoryDataResult] = useState<DailyCategoryBurndownResultRaw[]>([]);
    const [summaryMonthCostCategoryComparisonModel, setSummaryMonthCostCategoryComparisonModel] = useState<SummaryMonthCostCategoryComparisonModel[]>([]);
    const [tagsSplitResult, setTagsSplitResult] = useState<TagsSplitResult[]>([]);

    const [loadingDailyCategoryBurndown, setLoadingDailyCategoryBurndown] = useState<boolean>(false);
    const [loadingTagSplit, setLoadingTagSplit] = useState<boolean>(false);
    const [loadingMonthCostCategoryComparison, setLoadingMonthCostCategoryComparison] = useState<boolean>(false);

    const [customerId, setCustomerId] = useState<string>('00000000-0000-0000-0000-000000000000');
    
    const [budgetData, setBudgetData] = useState<TenantBudgetItem | any>({});

    const [startDate, setStartDate] = useState<string>(getStartUtcDate());
    const [endDate, setEndDate] = useState<string>(getEndUtcDate());

    useEffect(() => {
        setCustomerId(commonService.getAccountId());
        getBudget();
        getAllSubscriptions();
        getAllProject();
        
    }, []);

    useEffect(() => {
        if (customerId !== '00000000-0000-0000-0000-000000000000') {
            getDailyCategoryBurndown();
            getTagSplit();
            getMonthCostCategoryComparison();
        }
    }, [customerId]);

    

    const getTagSplit = () => {
        setLoadingTagSplit(true);
        ReportService.getTagSplit(customerId, startDate, endDate).then((response: any) => {
            setTagsSplitResult(response.data);
            setLoadingTagSplit(false);

        })
            .catch((e: Error) => {
                console.log(e);
                setLoadingTagSplit(false);
            });
    }

    const getBudget = () => {
        CloudResourceService.listTenantBudget().then((response: any) => {
            const result: TenantBudgetItem[] = response?.data?.result || [];
            setBudgetData(result[0] || {});
            stats[2].value = commonService.formatCurrency(result[0]?.budget || 0);
        })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const getDailyCategoryBurndown = () => {
        setLoadingDailyCategoryBurndown(true);
        ReportService.getDailyCategoryBurndown(customerId, startDate, endDate).then((response: any) => {
            setMeterCategoryDataResult(parseBurndownData(response.data || []));
            setLoadingDailyCategoryBurndown(false);

        })
            .catch((e: Error) => {
                setLoadingDailyCategoryBurndown(false);
                console.log(e);
            });
    }

    const parseBurndownData = (data: any[]) => {
      data.forEach(x => {
        const objectData = JSON.parse(x?.data || '{}');
        for(const y in objectData) {
          objectData[y] = commonService.fixDecimal(objectData[y] || 0);
        }
        x.data = JSON.stringify(objectData);
      });
      return data;
    }

    const getMonthCostCategoryComparison = () => {
        setLoadingMonthCostCategoryComparison(true);
        ReportService.getMonthCostCategoryComparison(customerId, +(new Date(endDate).getUTCMonth()), +(new Date(endDate).getUTCFullYear())).then((response: any) => {
            setLoadingMonthCostCategoryComparison(false);
            const dataLoad: MonthCostCategoryComparisonResult[] = response.data.filter(filterIfCostIsZero);
            const currentInvoice = dataLoad.reduce((sum, current) => sum + current.cost, 0);
            const prevInvoice = dataLoad.reduce((sum, current) => sum + current.prevCost, 0);
            const currentInvoiceChange = commonService.getPercentageChange(prevInvoice, currentInvoice)
            stats[0].value = commonService.formatCurrency(currentInvoice);
            stats[0].change = currentInvoiceChange;

            const currentCount = dataLoad.reduce((sum, current) => sum + current.resourceCount, 0);
            const prevCount = dataLoad.reduce((sum, current) => sum + current.prevResourceCount, 0);
            const currentCountChange = commonService.getPercentageChange(prevCount, currentCount)
            stats[1].value = currentCount.toString();
            stats[1].change = currentCountChange;


            const currentTagCompliance = aggregateElements(dataLoad)
            const prevTagCompliance = aggregateElementsPrev(dataLoad);
            const currentTagComplianceChange = commonService.getPercentageChange(prevTagCompliance, currentTagCompliance)
            stats[3].value = currentTagCompliance.toString() + "%";
            stats[3].change = currentTagComplianceChange;
            const summaryMonthCostCategoryComparisonModelDataLoad = groupAndSumByCategoryAndRegion(dataLoad);
            const sortedSummaryDates = summaryMonthCostCategoryComparisonModelDataLoad.sort((a, b) => {
                if (a.region < b.region) return -1;
                if (a.region > b.region) return 1;
                if (a.category < b.category) return -1;
                if (a.category > b.category) return 1;
                return 0;
            });
            console.log(sortedSummaryDates)
            setSummaryMonthCostCategoryComparisonModel(sortedSummaryDates);


        })
            .catch((e: Error) => {
                setLoadingMonthCostCategoryComparison(false);
                console.log(e);
            })
    }

    const [showBudgetModal, setShowBudgetModal] = useState<boolean>(false);
    const [isEditBudget, setIsEditBudget] = useState<boolean>(false);

    const openBudgetModal = (isEdit?: boolean) => {
      setIsEditBudget(!!isEdit);
      setShowBudgetModal(true);
    };

    const closeVMDetailModal = (refresh?: boolean) => {
      if (refresh) {
        getBudget();
      }
      setShowBudgetModal(false);
    };

    const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>([]);
    const [projects, setProjects] = useState<ProjectItem[]>([]);

    const getAllSubscriptions = () => {
      CloudResourceService.getAllSubscriptions()
        .then((response: any) => {
          const result = response?.data?.result || [];
          setSubscriptionList(result);
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
        });
    };

    const getAllProject = () => {
      OpportunityService.getAllProject(commonService.userDetail()?.accountId)
        .then((response: any) => {
          const result: ProjectItem[] = response?.data?.result || [];
          setProjects(result);
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
        });
    };

    return (
      <>
        {showBudgetModal && (
          <BudgetModal
            show={showBudgetModal}
            budget={budgetData}
            closeModal={closeVMDetailModal}
            isEdit={isEditBudget}
            subscriptionList={subscriptionList}
            projects={projects}
          />
        )}

        <div className="py-10">
          <header>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                Dashboard
              </h1>
            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative isolate overflow-hidden">
                <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
                  {stats.map((stat) => (
                    <div
                      key={stat.name}
                      className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
                    >
                      <dt className="text-sm font-medium leading-6 text-gray-500">
                        {stat.name}
                      </dt>
                      <dd
                        className={commonService.classNames(
                          stat.changeType === "negative"
                            ? "text-rose-600"
                            : "text-green-500",
                          "text-xs font-medium"
                        )}
                      >
                        {stat.change}
                      </dd>
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        {stat.value}
                        {stat.name === 'Budget' && <div className='flex'>
                          <button
                            type="submit"
                            className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => openBudgetModal()}
                          >
                            Add
                          </button>
                          {budgetData?.id && <button
                            type="submit"
                            className="ml-2 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => openBudgetModal(true)}
                          >
                            Edit
                          </button>}
                        </div>}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="relative space-y-6 mb-4">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">
                    Daily Invoice Breakdown
                  </span>
                </div>
              </div>
              {loadingDailyCategoryBurndown ? (
                <Pulse show={loadingDailyCategoryBurndown} />
              ) : (
                <MontlhyInvoiceReportChartAll
                  key="comp2"
                  data={meterCategoryDataResult}
                />
              )}

              {/* 

                            <div className="relative space-y-6">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center">
                                    <span className="bg-white px-2 text-sm text-gray-500">Commitments</span>
                                </div>
                            </div>


                            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 ">

                                <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <CommitmentTrackingChart data={CommitementDataSet} title='Reservations Tracking' components={ComponentsReservation} />
                                </li>
                                <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <CommitmentTrackingChart data={SavingsPlanDataSet} title='Savings Plan Tracking' components={ComponentsReservation} />
                                </li>
                                <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <CommitmentTrackingChart data={LicensesPlanDataSet} title='Licenses Tracking' components={ComponentsReservation} />
                                </li>
                            </ul> */}

              <div className="relative space-y-6">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">
                    Category
                  </span>
                </div>
              </div>
              {loadingMonthCostCategoryComparison ? (
                <Pulse show={loadingMonthCostCategoryComparison} />
              ) : (
                <SummaryMonthCostCategoryComparison
                    key="comp3"
                    data={summaryMonthCostCategoryComparisonModel}
                  />
              )}

              <div className="relative space-y-6">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">
                    Tags breakdown
                  </span>
                </div>
              </div>
              {loadingTagSplit ? (
                <Pulse show={loadingTagSplit} />
              ) : (
                <ChartCart data={tagsSplitResult} />
              )}
            </div>
          </main>
        </div>
      </>
    );
}