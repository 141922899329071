import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../App";
import Pulse from "../pulse/pulse";
import AuthenticationService from "../service/authService";
import commonService from "../service/commonService";

export default function AppRedirect() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {setIsCustomer}: any = useContext(MyContext);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    const currentUser =
    JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
    if (commonService.isValidToken(navigate) && currentUser?.id) {
      setLoading(true);
      if(!commonService.selectedAccount.id) {
        commonService.selectedAccount.id = currentUser?.accountId;
      }
      AuthenticationService.getUserByIdSync(currentUser?.id)
        .then((response: any) => {
          const params = new URLSearchParams(window.location.search);
          let path = params.get("path") ?? "/";
          
          const result: User = response?.data?.result;
          const account: AccountItem | undefined = (result?.accounts || []).find(
            (x) => x.id === (commonService.selectedAccount.id || result?.accountId)
          );
          const customer = account?.type === "Customer";
          setIsCustomer(customer);
          if(!customer) {
            path = '/resellerProposal';
          }
          commonService.checkOnBoardProcess(response, navigate, true, path);
          setLoading(false);
        })
        .catch((e: any) => {
          setLoading(false);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="py-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {loading && <Pulse show={loading} />}
          </div>
        </div>
      </div>
  );
}
