import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal: () => void;
}

export default function VersionModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [authVersion, setAuthVersion] = useState<VersionHealthCheck>();
  const [portalVersion, setPortalVersion] = useState<VersionHealthCheck>();

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      const authVersionData = JSON.parse(
        JSON.stringify(commonService.authVersion || {})
      );
      authVersionData.buildDate = authVersionData?.buildDate
        ? new Date(authVersionData?.buildDate).toLocaleDateString()
        : "";
      setAuthVersion(authVersionData as VersionHealthCheck);
      const portalVersionData = JSON.parse(
        JSON.stringify(commonService.portalVersion || {})
      );
      portalVersionData.buildDate = portalVersionData?.buildDate
        ? new Date(portalVersionData?.buildDate).toLocaleDateString()
        : "";
      setPortalVersion(portalVersionData as VersionHealthCheck);
    }
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg lg:max-w-lg sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Versions
            </Dialog.Title>
            <div className="flex justify-between p-8">
              <div>
                <p>API : Auth</p>
                <p>Version : {authVersion?.number}</p>
                <p>Build Date : {authVersion?.buildDate}</p>
              </div>
              <div>
                <p>API : Portal</p>
                <p>Version : {portalVersion?.number}</p>
                <p>Build Date : {portalVersion?.buildDate}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
