import { CalendarDaysIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import TooltipText from "../../tooltip";
import ContactModal from "../modal/contactsModal";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";

interface Props {
  plan: OpportunityPlanItem | undefined;
  refreshDetails?: any;
  printPlan: () => void
  pdfLoading: boolean
}

interface ContactRole {
  role: string;
  contacts: OpportunityPlanContactsItem[];
}

export function InitiativeDetailSummaryCard(props: Readonly<Props>) {
  const initPlan = () => {
    const currentPlan = JSON.parse(JSON.stringify(props.plan ?? {}));
    currentPlan.startOn = commonService.getDateString(currentPlan.startOn);
    currentPlan.dueOn = commonService.getDateString(currentPlan.dueOn);
    return currentPlan;
  };

  const plan: OpportunityPlanItem = initPlan();

  const [showDetailModal, setShowDetailModal] = useState(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] =
    useState<OpportunityPlanContactsItem>();

  const [contactRoleList, setContactRoleList] = useState<ContactRole[]>([]);

  useEffect(() => {
    const contactList = props?.plan?.opportunityPlanContacts ?? [];
    const parseContactList: ContactRole[] = [];
    contactList?.forEach((x) => {
      const selectedContact = parseContactList.find((p) => p.role === x.role);
      if (selectedContact) {
        selectedContact.contacts.push(x);
      } else {
        const contact: ContactRole = {
          role: x.role,
          contacts: [x],
        };
        parseContactList.push(contact);
      }
      setContactRoleList(parseContactList);
    });
  }, [props?.plan]);

  const closeDetailModal = (refresh?: boolean) => {
    setShowDetailModal(false);
    if (refresh) {
      props.refreshDetails(true);
    }
  };

  const openContactModal = () => {
    setIsEdit(false);
    openDetailModal();
  };

  const openDetailModal = () => {
    setShowDetailModal(true);
  };

  const editContact = (contact: OpportunityPlanContactsItem) => {
    setSelectedContact(contact);
    setIsEdit(true);
    openDetailModal();
  };

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const printPlan = () => {
    if (!pdfLoading) {
      props?.printPlan();
    }
  };

  useEffect(() => {
    setPdfLoading(props?.pdfLoading);
  }, [props?.pdfLoading]);

  return (
    <>
      <ContactModal
        show={showDetailModal}
        closeModal={closeDetailModal}
        isEdit={isEdit}
        plan={props?.plan}
        contactItem={selectedContact}
        refreshDetails={props.refreshDetails}
      />

      <div className={commonService.classNames(pdfLoading ? "lg:col-start-1 lg:col-span-2 lg:row-start-2  px-16": "lg:col-start-3 lg:row-end-1")}>
        <h2 className="sr-only">Summary</h2>
        <div className={commonService.classNames('rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5', 
        pdfLoading ? "pt-4 pb-20": "")}>
          <dl className="flex flex-wrap">
            <div className="flex-auto pl-6 pt-6">
              <dt className="text-sm font-semibold leading-6 text-gray-900">
                Vaule
              </dt>
              <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                ${plan?.value}
              </dd>
            </div>
            <div className="flex-none self-end px-6 pt-4">
              <dt className="sr-only">Status</dt>
              <dd className={commonService.classNames("px-2 py-1 text-xs font-medium ring-1 ring-inset", 
              pdfLoading ? "rounded-lg px-4 pt-1 pb-4": "rounded-md")}>
                {plan?.status}
              </dd>
            </div>

            {(contactRoleList || [])?.length > 0 ? (
              <>
                {(contactRoleList || []).map(
                  (contact: ContactRole) => (
                    <div
                      className="mt-4 flex w-full flex-none gap-x-4 px-6 items-center"
                      key={"index" + contact.role}
                    >
                      <dt className="flex-none">
                        <UserCircleIcon
                          className="h-6 w-5 text-gray-400"
                          aria-hidden="true"
                        ></UserCircleIcon>
                      </dt>
                      <dd className="text-sm leading-2 text-gray-900">
                        {contact.role}:
                      </dd>

                      {(contact.contacts || []).map(
                        (contactItem: OpportunityPlanContactsItem, j: number) => (
                          <>
                            <dd className={commonService.classNames("text-sm font-medium leading-6 text-gray-900",
                            pdfLoading ? "": "truncate ...")}>
                              <a href="#" onClick={() => editContact(contactItem)} data-tooltip-id={
                                    contactItem.id + j
                                  }>
                                {contactItem.externalUserName ||
                                  contactItem.user?.firstName}
                              </a>
                              <TooltipText id={contactItem.id + j} tooltipText={(contactItem.externalUserName ||
                                  contactItem.user?.firstName) ?? ''} />
                            </dd>
                            {contactItem?.isApprover && (
                              <dd className="text-green-600">
                                <a
                                  href="#"
                                  data-tooltip-id={
                                    contactItem.id + "-contact-tooltip"
                                  }
                                >
                                  <CheckCircleIcon
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                  />
                                </a>
                                <Tooltip
                                  id={contactItem.id + "-contact-tooltip"}
                                  arrowColor="transparent"
                                  place="bottom"
                                >
                                  <div style={{}}>
                                    <span>Approver</span>
                                  </div>
                                </Tooltip>
                              </dd>
                            )}
                            {j !== (contact.contacts || []).length-1 && ','}
                          </>
                        )
                      )}
                    </div>
                  )
                )}
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6" data-html2canvas-ignore="true">
                  <button
                    type="button"
                    className=" w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => openContactModal()}
                  >
                    Add Contact
                  </button>
                </div>
              </>
            ) : (
              <button
                type="button"
                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-4 mt-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => openContactModal()}
              >
                <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                      />
                    </svg>
                <span className="mt-2 block text-sm font-semibold text-gray-900" data-html2canvas-ignore="true">
                  Create Contact
                </span>
              </button>
            )}

            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
              <dt className="flex-none">
                <span className="sr-only">Due date</span>
                <CalendarDaysIcon
                  className="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd className="text-sm leading-2 text-gray-900">Start Date</dd>
              <dd className="text-sm leading-6 text-gray-500">
                <time dateTime="2023-01-31">{plan?.startOn}</time>
              </dd>
            </div>
            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
              <dt className="flex-none">
                <span className="sr-only">Due date</span>
                <CalendarDaysIcon
                  className="h-6 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd className="text-sm leading-2 text-gray-900">Due Date</dd>
              <dd className="text-sm leading-6 text-gray-500">
                <time dateTime="2023-01-31">{plan?.dueOn}</time>
              </dd>
            </div>
          </dl>
          <div className="mt-6 border-t border-gray-900/5 px-6 py-6" data-html2canvas-ignore="true">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={() => printPlan()}
            >
              <Spinner show={pdfLoading} />
              Export to PDF <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
